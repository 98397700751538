import { React, useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { salesPayment } from 'api';
import { statusCode } from './component/common/common';
import { checkSalesPaymentManualLink } from 'constants/hyperlink';
import queryString from 'query-string';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import moment from 'moment';
import './check-sales-payment.scss';

const CheckSalesPayment = () => {
  const query = queryString.parse(decodeURIComponent(window.location.href));
  const [text, setText] = useState(query.searchtext || '');
  const [dateValue, setDateValue] = useState({
    fromDate: query.fromdate || moment().subtract(8, 'day').format('YYYY-MM-DD'),
    toDate: query.todate || moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const history = useHistory();

  const pivotDataSource = useMemo(() => {
    const customDataSource = new PivotGridDataSource({
      fields: [
        {
          caption: '회사명',
          dataField: 'CompanyName',
          width: 100,
          area: 'row',
          expanded: true,
        },
        {
          caption: 'P코드',
          dataField: 'HiParkingID',
          width: 100,
          area: 'row',
          expanded: true,
        },
        {
          caption: '사업소코드',
          dataField: 'CostCenterCode',
          width: 100,
          area: 'row',
          expanded: true,
        },
        {
          caption: '사업소명',
          width: 120,
          dataField: 'CostCenterName',
          area: 'row',
          expanded: true,
        },
        {
          caption: '대사기준일',
          dataField: 'SalesDate',
          area: 'column',
        },
        {
          caption: '상태',
          dataField: 'CurrentStatus',
          dataType: 'string',
          summaryType: 'custom',
          area: 'data',
          calculateCustomSummary: options => {
            if (options.summaryProcess === 'calculate') {
              options.totalValue = options.value;
            }
          },
          customizeText: cellInfo => {
            return statusCode(cellInfo.valueText);
          },
        },
      ],

      store: new CustomStore({
        key: 'CompanyName',
        loadMode: 'raw',
        load: async () => {
          const defaultFromDate = moment().subtract(8, 'day').format('YYYY-MM-DD');
          const defaultToDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
          const searchParams = {
            FromDate: query.fromdate || defaultFromDate,
            ToDate: query.todate || defaultToDate,
            SearchText: text || '',
            UserID: '',
          };
          const result = await salesPayment.getSummaryByCostCenter(searchParams);
          return result.data;
        },
      }),
    });

    return customDataSource;
  }, [query]);

  const linkStyle = value => {
    if (value === 'N') {
      return 'rgb(236 74 74)';
    } else if (value === 'O') {
      return '#3F51B5';
    } else {
      return 'rgb(51 202 139)';
    }
  };

  const className = value => {
    if (value === 'N') {
      // 대사 전
      return 'before';
    } else if (value === 'O') {
      // 대사 중
      return 'doing';
    } else {
      return 'end';
    }
  };

  const customizeCells = useCallback(e => {
    const { area, cell, cellElement } = e;
    if (area === 'data' && cell.rowPath && cell.rowPath.length === 1) {
      cellElement.style.cursor = 'cursor';
      cellElement.style.opacity = 0;
    } else if (cell.value === 'A' || cell.value === 'N' || cell.value === 'O') {
      cellElement.style.cursor = 'pointer';
      cellElement.style.color = linkStyle(cell.value);
      cellElement.style.outline = 'none';
      cellElement.style.width = 'fit-content';
      cellElement.className = className(cell.value);
    }
  }, []);

  const onCellClick = e => {
    const {
      area,
      cell: { rowPath, columnPath },
    } = e;
    if (area === 'data') {
      if (rowPath && rowPath.length === 1) {
        // 접힌 셀 이동 막기
      } else {
        const nowURL = window.location.href; // 여기서 ? 가 있으면 빼고 baseUrl 만들기
        const baseURL = nowURL.split('?')[0];
        const searchQParam = nowURL.split('?')[1];
        const path = baseURL.split('/#/');
        const pathName = path[1];
        const url = `&date=${columnPath[0]}&code=${rowPath[2]}`;
        const encoded = encodeURIComponent(url);

        history.push(`/${pathName}/detail?` + searchQParam + encoded);
      }
    }
  };

  const generateQueryParams = (dateValue, text, query) => {
    const nowURL = window.location.href;
    const baseURL = nowURL.split('?')[0];
    const path = baseURL.split('/#/');
    const pathName = path[1];

    const url = `&fromdate=${dateValue.fromDate}&todate=${dateValue.toDate}&searchtext=${text ? text.trim() : ''}`;
    const encoded = encodeURIComponent(url);

    return `/${pathName}?${encoded}`;
  };

  const onEnterKey = e => {
    onSearch();
  };

  const onSearch = () => {
    const queryParams = generateQueryParams(dateValue, text, query);
    history.replace(queryParams);
  };

  const onChangeSearchText = e => {
    setText(e.value);
    if (!e.value) {
      const queryParams = generateQueryParams(dateValue, '', query);
      history.replace(queryParams);
    }
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    const queryParams = generateQueryParams({ ...dateValue, fromDate: e.value }, text, query);
    history.replace(queryParams);
  };

  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    const queryParams = generateQueryParams({ ...dateValue, toDate: e.value }, text, query);
    history.replace(queryParams);
  };

  return (
    <div>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>매출 대사{window.location.href.includes('history-sales') ? ' 이력 조회' : ''}</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={checkSalesPaymentManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <Item location={'before'}>
              <div className="label">대사기준일 :</div>
            </Item>
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />
            </Item>
            <Item location={'before'} text="~" />
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </Item>
            <Item location={'before'}>
              <TextBox
                labelMode="static"
                stylingMode="outlined"
                placeholder="회사명/사업소코드/사업소명"
                onValueChanged={onChangeSearchText}
                onEnterKey={onEnterKey}
                width={250}
                mode="search"
                defaultValue={query.searchtext || ''}
              />
            </Item>
            <Item location={'after'}>
              <Button type="normal" stylingMode="contained" icon="search" text="조회" width={120} onClick={onSearch} />
            </Item>
          </Toolbar>
          <PivotGrid
            // ref={pivotGridRef}
            className={'dx-card wide-card payment-pivot'}
            dataSource={pivotDataSource}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            showRowGrandTotals={false}
            hoverStateEnabled={true}
            height={650}
            onCellClick={onCellClick}
            onCellPrepared={customizeCells}
          >
            <Scrolling mode="virtual" showScrollbar="always" />
            <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
            <FieldChooser enabled={false} />
          </PivotGrid>
        </div>
      </div>
    </div>
  );
};

export default CheckSalesPayment;
