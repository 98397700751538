import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { master } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './popup.scss';

/* 사용시

1. 사용하는 곳에서 ref 걸어주기 => open 
2. 저장시 받는 값 setSelectedCostCenter 

*/
const CostcenterMaster = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [text, setText] = useState('');
  const [SearchText, setSearchText] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
    close,
  }));

  useEffect(() => {
    (async () => {
      const result = await master.searchCostCenterMaster(SearchText);
      if (result.isOk) {
        const data = [...result.data];
        if (props.isCostcentermng) {
          // 사용자별 사업소 관리일때만 전체 추가
          data.unshift({ HiParkingID: '*', CostCenterName: '전체', CostCenterCode: '*', UseYN: 'Y' });
        }
        setDataSource(data);
      }
    })();
  }, [SearchText, isRefresh]);

  const onEnterKey = () => {
    setSearchText({ SearchText: text ? text.trim() : '' });
  };

  const onValueChanged = e => {
    setText(e.value);
  };

  const useYNcellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'Y' ? '예' : '아니오';
  };

  const open = () => {
    setIsPopup(true);
    setIsRefresh(!isRefresh);
    gridRef.current.instance.refresh();
    gridRef.current.instance.clearSelection();
    gridRef.current.instance.clearFilter();
  };

  const close = () => {
    setIsPopup(false);
    refresh();
  };

  const onSave = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    props.setSelectedCostCenter(selectedItems);
    setIsPopup(false);
    refresh();
  };

  const refresh = () => {
    gridRef.current.instance.refresh();
    gridRef.current.instance.clearSelection();
    gridRef.current.instance.clearFilter();
  };

  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="30%" height={760}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <div className="popup-header">사업소 선택</div>
        <div className="popup-content">
          <div className="dx-fieldset">
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isHeader={props.isSalesCostReport ? false : true}
              height={500}
              headerBefore={[
                <TextBox
                  mode="search"
                  labelMode="static"
                  width={200}
                  stylingMode="outlined"
                  placeholder="코스트센터코드/코스트센터명"
                  onValueChanged={onValueChanged}
                  onEnterKey={onEnterKey}
                />,
              ]}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Selection mode={props.isMulti ? 'multiple' : 'single'} selectAllMode="pages" allowSelectAll={true} />
              <Column dataField={'CostCenterName'} caption={'코스트센터명'} width={'40%'} />
              <Column dataField={'HiParkingID'} caption={'관리 ID'} width={'20%'} />
              <Column dataField={'UseYN'} caption={'사용여부'} cellRender={useYNcellRender} width={'10%'} />
            </CommonDataGrid>
          </div>
        </div>
        <div className="popup-footer">
          <div>
            <Button text="선택" width={'120'} onClick={onSave} />
          </div>
          <div>
            <Button text="닫기" width={'120'} onClick={close} />
          </div>
        </div>
      </div>
    </Popup>
  );
});

export default CostcenterMaster;
