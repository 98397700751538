import React, { useRef, useEffect, useState, useCallback } from 'react';
import { TotalItem, Summary, Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import { system, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { useAuth } from 'contexts/auth';
import CommonDataGrid from 'common/default-data-grid';
import CostcenterMaster from 'common/popup/costcenter-master';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';

const Costcentermng = () => {
  const { role } = useAuth();

  const gridRef = useRef({});
  const lookupRef = useRef({});

  const costcenterMasterRef = useRef({});
  const [userList, setUserList] = useState([]);
  const [userId, setUserID] = useState('*');
  const [checkedId, setCheckedID] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  // 중복 제거 -> 논의 후 처리
  // const [duplication, setDuplication] = useState([]);

  const useYNcellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'Y' ? '예' : '아니오';
  };

  useEffect(() => {
    (async () => {
      await system
        .searchCostCenterManager({
          ManagerID: userId,
        })
        .then(res => {
          if (res.isOk) {
            setDataSource(res.data);
          }
        });
    })();
  }, [userId, isRefresh]);

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      // let arr2 = dataSource.filter(x1 => selectedCostCenter.some(x2 => x1.CostCenterCode === x2.CostCenterCode));
      // console.log('arr2 >', arr2);
      const data = [...dataSource, ...selectedCostCenter];
      setDataSource(data);
    }
  }, [selectedCostCenter]);

  useEffect(() => {
    (async () => {
      await system
        .userList({
          EmployeeName: '',
          EmployeeEnName: '',
          NickName: '',
          IsActive: 1,
          RoleCodeList: role,
        })
        .then(res => {
          if (res.isOk) {
            const user = [];
            const userList = res.data.map(u => {
              return {
                name: u.EmployeeName,
                value: u.UserID,
              };
            });
            user.push({
              name: '전체',
              value: '*',
            });
            user.push(...userList);
            setUserList(user);
          }
        });
    })();
  }, []);

  const onItemClick = e => {
    const { value } = e.itemData;
    setUserID(value);
  };

  const onAdd = useCallback(async () => {
    if (selectedCostCenter.length === 0) {
      alert('사업소를 추가하세요');
    } else {
      const code = selectedCostCenter.map(a => a.CostCenterCode);
      const result = confirm('<i>저장하시겠습니까?</i>', '확인');
      result.then(async dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          await system
            .insertCostCenterManager({
              CostCenterCode: code.join(','),
              ManagerID: userId,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '저장되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );

                setSelectedCostCenter([]);
                setIsRefresh(!isRefresh);
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  }, [userId, selectedCostCenter, isRefresh]);

  const onRemove = useCallback(async () => {
    const selectItems = gridRef.current.instance.getSelectedRowsData();
    if (selectItems.length === 0) {
      alert('사업소를 선택해주세요');
    } else {
      const code = selectItems.map(a => a.CostCenterCode);
      const result = confirm('<i>삭제하시겠습니까?</i>', '확인');
      result.then(async dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          await system
            .deleteCostCenterManager({
              CostCenterCode: code.join(','),
              ManagerID: userId,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '삭제되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                setIsRefresh(!isRefresh);
                gridRef.current.instance.clearSelection();
              } else {
                setLoadPanelVisible(false);

                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  }, [userId, isRefresh]);

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsSummary') {
      options.totalValue = checkedId.length;
    }
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onSelectionChanged = e => {
    const { value } = e.selectedItem;
    setUserID(value);
  };
  const onCheckSelectionChanged = e => {
    setCheckedID(e.selectedRowKeys);
  };
  return (
    <div>
      <h2 className={'content-block'}>사용자별 사업소 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            allowColumnResizing={true}
            hoverStateEnabled={true}
            onSelectionChanged={onCheckSelectionChanged}
            height={700}
            isHeader={true}
            isExcel={true}
            headerBefore={[
              <Lookup
                ref={lookupRef}
                labelMode="static"
                stylingMode="outlined"
                dataSource={userList}
                valueExpr="value"
                displayExpr="name"
                searchExpr="name"
                searchEnabled={true}
                dropDownCentered={false}
                width={335}
                placeholder="사용자를 선택해주세요"
                onSelectionChanged={onSelectionChanged}
                mode="search"
              >
                <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
              </Lookup>,
            ]}
            headerAfter={[
              <Button text="저장" type="normal" stylingMode="contained" onClick={onAdd} />,
              <Button text="삭제" type="normal" stylingMode="contained" onClick={onRemove} />,
              <Button
                text="사업소 추가하기"
                type="normal"
                stylingMode="contained"
                onClick={() => costcenterMasterRef.current.open()}
              />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Selection mode="multiple" selectAllMode="pages" />
            <Column dataField={'HiParkingID'} caption={'관리 ID'} />
            <Column dataField={'CostCenterCode'} caption={'코스트센터코드'} visible={false} />
            <Column dataField={'CostCenterName'} caption={'코스트센터명'} />
            <Column dataField={'UseYN'} caption={'사용여부'} cellRender={useYNcellRender} />
            <Column dataField={'ManagerName'} caption={'담당자'} />
            <Summary calculateCustomSummary={calculateSelectedRow}>
              <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem
                name="SelectedRowsSummary"
                summaryType="custom"
                displayFormat="선택 {0}건"
                showInColumn="CostCenterName"
              />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <CostcenterMaster
        ref={costcenterMasterRef}
        setSelectedCostCenter={setSelectedCostCenter}
        isMulti
        isCostcentermng
      />
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default Costcentermng;
