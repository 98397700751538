import * as ExcelHelpers from '../excelHelpers';
import { exportPivotGrid } from 'devextreme/excel_exporter';

// 엑셀 - 길이를 기반으로 column 이름으로 변환
const columnToName = columnNumber => {
  let columnName = '';
  while (columnNumber > 0) {
    let remainder = (columnNumber - 1) % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    columnNumber = Math.floor((columnNumber - 1) / 26);
  }
  return columnName;
};

const getValueFromTable = (costData, searchCode) => {
  const targetRow = costData.find(row => row.CostDetailCode === searchCode);
  return targetRow ? targetRow.Value : null;
};
export async function createWorksheet1(worksheet, searchData, gridRef, profitRatio, costData) {
  const { CostCenterName, SalesMonth } = searchData;
  let salesTotalRowIdx = 0;
  let costTotalRowIdx = 0;

  const SalesYear = SalesMonth.substring(0, 4);

  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentRight = { vertical: 'middle', horizontal: 'right' };
  const wrapTextAlignment = {
    wrapText: true,
    vertical: 'middle',
    horizontal: 'center',
  };

  const startRowIdx = 6;
  const startColIdx = 2;

  const sFixedRentalAmountArr = costData[0].filter(a => a.구분1 === '추가_별도지급' && a.구분2 === '고정임대료');
  const sSubsidyAmountArr = costData[0].filter(a => a.구분1 === '추가_별도지급' && a.구분2 === '주차수당 지원금');

  const sFixedRentalAmount = sFixedRentalAmountArr[0]?.['총계'];
  const sSubsidyAmount = sSubsidyAmountArr[0]?.['총계'];

  const sDisbursementCount =
    sFixedRentalAmount > 0 && sSubsidyAmount > 0 ? 2 : sFixedRentalAmount > 0 || sSubsidyAmount > 0 ? 1 : 0;

  const startColName = columnToName(startColIdx);
  const exporPivotResult = exportPivotGrid({
    component: gridRef.current.instance,
    worksheet: worksheet,
    topLeftCell: { row: startRowIdx, column: startColIdx },
    customizeCell: ({ pivotCell, excelCell }) => {
      if (pivotCell.type && pivotCell.type === 'D') {
        excelCell.alignment = alignmentCenter;
      }
      if (pivotCell.dataType === 'number') {
        excelCell.style = { numFmt: '#,##0', alignment: { horizontal: 'right' } };
      }
      if (pivotCell.area === 'column' && pivotCell.type === 'GT') {
        excelCell.value = `누계         (${SalesYear}.01~${SalesYear}.12)`;
      }
      if (pivotCell.area === 'row' && pivotCell.text === '매출 Total') {
        excelCell.value = '매출';
        salesTotalRowIdx = pivotCell.rowIndex;
      }
      if (pivotCell.area === 'row' && pivotCell.text === '비용 Total') {
        excelCell.value = '비용합계';
        costTotalRowIdx = pivotCell.rowIndex;
      }
      if (pivotCell.area === 'row' && pivotCell.text === '추가_별도지급') {
        excelCell.value = '청구';
      }
      if (pivotCell.area === 'row' && pivotCell.text === '추가_별도지급 Total') {
        excelCell.value = ' ';
      }
    },
  });
  await exporPivotResult;
  const newRow1 = worksheet.insertRow(startRowIdx + salesTotalRowIdx + 1); // 부가세
  const newRow2 = worksheet.insertRow(startRowIdx + salesTotalRowIdx + 2); // 순수입
  const newRow3 = worksheet.insertRow(startRowIdx + costTotalRowIdx + 3); // 수  익

  const newRow4 = worksheet.insertRow(startRowIdx + costTotalRowIdx + 4); // 수익(사업소)
  const newRow5 = worksheet.insertRow(startRowIdx + costTotalRowIdx + 5); // 배분(하이파킹)
  // const newRow6 = worksheet.insertRow(startRowIdx + costTotalRowIdx + 7); // 최종 수익금

  newRow1.values = ['', '매출', '부가세'];
  newRow2.values = ['', '매출', '순수입'];
  newRow3.values = ['', '수  익', ''];
  newRow4.values = ['', '수익', `${CostCenterName}`];
  newRow5.values = ['', '배분', '하이파킹'];

  const lastRowIdx = worksheet.rowCount;
  const lastColIdx = worksheet.columnCount;
  const lastColName = columnToName(lastColIdx);
  const remarkColIdx = lastColIdx + 1;
  const remarkColName = columnToName(remarkColIdx);

  const mergedSStartRow = startRowIdx + 1;
  const mergedSLastRow = startRowIdx + salesTotalRowIdx + 2;
  const mergedCStartRow = startRowIdx + salesTotalRowIdx + 3;
  const mergedCLastRow = startRowIdx + costTotalRowIdx + 2;
  worksheet.unMergeCells(`B7:B${lastRowIdx}`);
  worksheet.mergeCells(`B${mergedSStartRow}:B${mergedSLastRow}`); // 매출 MERGE
  worksheet.mergeCells(`B${mergedCStartRow}:B${mergedCLastRow}`); // 비용 MERGE
  worksheet.mergeCells(`B${startRowIdx + costTotalRowIdx + 3}:C${startRowIdx + costTotalRowIdx + 3}`); // 수익 MERGE
  worksheet.mergeCells(`B3:${remarkColName}3`);
  worksheet.getCell('B5').value = `■ 기 간: ${SalesMonth.replace('-', '년 ')}월`;
  worksheet.getCell('B6').value = '구  분';
  worksheet.getCell('B7').value = '매  출';
  worksheet.getCell(`B${startRowIdx + costTotalRowIdx + 2}`).value = '비  용';
  worksheet.getCell(`C${startRowIdx + salesTotalRowIdx}`).value = '소  계';
  worksheet.getCell(`${remarkColName}5`).value = '(단위 : 원)';
  worksheet.getCell('B3').value = {
    richText: [{ text: `${CostCenterName} 주차수입 및 손익현황`, font: { size: 16, name: '휴먼둥근헤드라인' } }],
  };
  worksheet.getRow(startRowIdx).getCell(remarkColIdx).value = '비고';

  // 순수입 = 매출소계 - 부가세
  const salesStartRow = startRowIdx + 1;
  const salesTotalRow = startRowIdx + salesTotalRowIdx;
  const vatRow = salesTotalRow + 1;
  const incomeRow = vatRow + 1;
  ExcelHelpers.setCellFormulasForSalesTotal(worksheet, salesStartRow, salesTotalRow - 1, salesTotalRow, lastColName);
  ExcelHelpers.setCellFormulasForVAT(worksheet, salesTotalRow, vatRow, lastColName);
  ExcelHelpers.setCellFormulasForIncome(worksheet, salesTotalRow, vatRow, incomeRow, lastColName);

  // 매출-비용(수익)
  const costStartRow = incomeRow + 1;
  const costTotalRow = startRowIdx + costTotalRowIdx + 2;
  const profitRow = costTotalRow + 1;

  ExcelHelpers.setCellFormulasForCostTotal(worksheet, costStartRow, costTotalRow - 1, costTotalRow, lastColName);
  ExcelHelpers.setCellFormulasForProfit(worksheet, incomeRow, costTotalRow, profitRow, lastColName);

  for (let currentRow = mergedSStartRow; currentRow <= lastRowIdx; currentRow++) {
    const numFmt = '#,##0';
    worksheet.getCell(`${lastColName}${currentRow}`).numFmt = numFmt;
    worksheet.getCell(`${lastColName}${currentRow}`).value = {
      formula: `SUM(D${currentRow}:${columnToName(lastColIdx - 1)}${currentRow})`,
    };
  }

  // 수익(사업소), 배분(하이파킹)
  const profitParnerRow = profitRow + 1;
  const profitHiparkingRow = profitParnerRow + 1;
  ExcelHelpers.setCellFormulasForProfitShare(
    worksheet,
    profitRow,
    profitParnerRow,
    profitHiparkingRow,
    profitRatio,
    lastColName,
  );
  worksheet.mergeCells(`${remarkColName}${profitParnerRow}:${remarkColName}${profitHiparkingRow}`);

  // 별도청구 내역(고정임대료, 주차수당 지원금)
  if (sDisbursementCount > 0) {
    const fixedRentalRow = profitHiparkingRow + 1;
    const subsidyRow = fixedRentalRow + 1;
    const finalProfitRow = fixedRentalRow + sDisbursementCount;

    worksheet.getCell(`B${fixedRentalRow}`).value = '청구';
    worksheet.getCell(`B${finalProfitRow}`).value = '내역';
    worksheet.getCell(`C${finalProfitRow}`).value = '최종수익금';
    worksheet.getCell(`${remarkColName}${fixedRentalRow}`).value = `고정임대료 ${sFixedRentalAmount} 원 (VAT 별도)`;
    worksheet.mergeCells(`${remarkColName}${fixedRentalRow}:${remarkColName}${finalProfitRow}`);
    ExcelHelpers.setCellFormulasForFinalProfit(
      worksheet,
      profitParnerRow,
      fixedRentalRow,
      sSubsidyAmount > 0 ? subsidyRow : fixedRentalRow,
      finalProfitRow,
      lastColName,
    );

    worksheet.getCell(`${remarkColName}${fixedRentalRow}`).alignment = wrapTextAlignment;
    ExcelHelpers.applyBorderTopToRange(
      worksheet,
      fixedRentalRow,
      fixedRentalRow,
      startColName,
      remarkColName,
      'medium',
    );
    ExcelHelpers.applyFillGreyToRange(worksheet, finalProfitRow, finalProfitRow, 'C', remarkColName);
  }
  // 스타일 - 너비
  worksheet.getColumn('A').width = 1;
  worksheet.getColumn('B').width = 5;
  worksheet.getColumn('C').width = 20;
  worksheet.getColumn('D').width = 12;
  worksheet.getColumn('E').width = 12;
  worksheet.getColumn('F').width = 12;
  worksheet.getColumn('G').width = 12;
  worksheet.getColumn('H').width = 12;
  worksheet.getColumn('I').width = 12;
  worksheet.getColumn('J').width = 12;
  worksheet.getColumn('K').width = 12;
  worksheet.getColumn('L').width = 12;
  worksheet.getColumn('M').width = 12;
  worksheet.getColumn('N').width = 12;
  worksheet.getColumn('O').width = 12;
  worksheet.getColumn('P').width = 17;
  worksheet.getColumn(remarkColName).width = 17;

  // 스타일 - 정렬
  worksheet.getCell(`${remarkColName + startRowIdx}`).alignment = alignmentCenter;
  worksheet.getCell('B3').alignment = alignmentCenter;
  worksheet.getCell('B10').alignment = wrapTextAlignment;
  worksheet.getCell('P6').alignment = wrapTextAlignment;
  worksheet.getColumn('C').alignment = alignmentCenter;
  worksheet.getCell(`${remarkColName}5`).alignment = alignmentRight;
  worksheet.getCell(`B${startRowIdx + salesTotalRowIdx + 3}`).alignment = wrapTextAlignment;
  worksheet.getCell(`${remarkColName}${profitParnerRow}`).alignment = wrapTextAlignment;

  // 스타일 - 폰트
  worksheet.getCell(`B5`).font = { bold: true };
  worksheet.getCell(`C${mergedSLastRow}`).font = { bold: true };
  worksheet.getCell(`C${costTotalRow}`).font = { bold: true };
  worksheet.getCell(`C${profitRow}`).font = { bold: true };
  worksheet.getCell(`${remarkColName}5`).font = { bold: true };

  // 메모
  worksheet.getCell(`${remarkColName}${profitParnerRow}`).note =
    '적자일 경우 누적기준으로 흑자 발생 시 적자 부분 차감 후 지급';

  worksheet.getCell(`${remarkColName}${profitParnerRow}`).value = `배분비율 ${profitRatio * 100}:${
    100 - profitRatio * 100
  }         (부가세 별도)`;
  // 프린트 영역 설정
  worksheet.pageSetup.printArea = `A1:${remarkColName}10`;

  // 스타일 - 테두리 ex) ws1, 1,10, A, F, 'hair'
  ExcelHelpers.applyBorderAllToRange(worksheet, startRowIdx, lastRowIdx, startColName, remarkColName, 'hair');
  ExcelHelpers.applyBorderBottomToRange(
    worksheet,
    startRowIdx - 1,
    startRowIdx - 1,
    startColName,
    remarkColName,
    'medium',
  );
  ExcelHelpers.applyBorderBottomToRange(
    worksheet,
    profitHiparkingRow,
    profitHiparkingRow,
    startColName,
    remarkColName,
    'medium',
  );

  ExcelHelpers.applyBorderTopToRange(worksheet, lastRowIdx + 1, lastRowIdx + 1, startColName, remarkColName, 'medium');
  ExcelHelpers.applyBorderLeftToRange(
    worksheet,
    startRowIdx,
    lastRowIdx,
    columnToName(remarkColIdx + 1),
    columnToName(remarkColIdx + 1),
    'medium',
  );
  ExcelHelpers.applyBorderRightToRange(
    worksheet,
    startRowIdx,
    lastRowIdx,
    columnToName(startColIdx - 1),
    columnToName(startColIdx - 1),
    'medium',
  );
  ExcelHelpers.applyFillGreyToRange(worksheet, salesTotalRow, salesTotalRow, 'C', remarkColName);
  ExcelHelpers.applyFillGreyToRange(worksheet, incomeRow, incomeRow, 'C', remarkColName);
  ExcelHelpers.applyFillGreyToRange(worksheet, costTotalRow, costTotalRow, 'C', remarkColName);
  ExcelHelpers.applyFillGreyToRange(worksheet, profitParnerRow, profitParnerRow, 'C', remarkColName);
}
