import React, { useRef, useMemo, useState, useEffect } from 'react';
import CommonDataGrid from 'common/default-data-grid';
import ReportExport from './view-sales-cost-report-export';
import DateBox from 'devextreme-react/date-box';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import axios from 'axios';
import { CheckBox } from 'devextreme-react/check-box';
import CostcenterMaster from 'common/popup/costcenter-master';

import { profitDistributionReportManualLink } from 'constants/hyperlink';
import { getNormalDateFormat } from 'utils/common';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Button } from 'devextreme-react/button';
import { common } from 'api';

import './sales-payment-report.scss';

const userId = localStorage.getItem('userId');

const ViewSalesCostReportPage = () => {
  const gridRef = useRef({});
  const costcenterMasterRef = useRef({});
  const [lookupText, setLookupText] = useState('');
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);
  //검색
  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY-MM'),
    CostCenterCode: '',
    ByPaymentDate: 'N',
  });

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  // lookup 사업소 목록
  const [costCenterType, setCostCenterType] = useState([]);
  // lookup 선택된 사업소
  const [costcenterCode, setCostcenterCode] = useState([]);
  // 운영 보고서 datagrid
  const [dataSource, setDataSource] = useState([]);
  const [dataExtra, setDataExtra] = useState([]);

  // const [rawData, setRawData] = useState();
  const [costData, setCostData] = useState();

  useEffect(() => {
    (async () => {
      const result = await common.costcenterData();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);
  const onSearch = async () => {
    if (costcenterCode.length === 0) {
      return alert('사업소를 먼저 선택해주세요');
    }
    try {
      setLoadPanelVisible(true);
      const costresult = await axios.post(`aggregation/getSalesFinalReport`, {
        ...searchData,
        CostCenterCode: costcenterCode,
        LoginID: userId,
      });

      setLoadPanelVisible(false);
      setDataSource(costresult.data[0]);

      const parkingDataTable = Object.entries(costresult.data[3][0]).map(([key, value]) => ({
        속성: key,
        값: value,
      }));
      setDataExtra(parkingDataTable);
      setCostData(costresult.data);
      // setRawData(result.data);
    } catch (e) {
      setLoadPanelVisible(false);
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  };
  useEffect(() => {
    if (selectedCostCenter.length === 0) {
      setLookupText('');
      return;
    }
    const codes = selectedCostCenter.map(a => a.CostCenterCode).join(', ');
    const length = selectedCostCenter.length;

    if (length > 1) {
      const { CostCenterCode: dpCode, CostCenterName: dpName } = selectedCostCenter[0];
      const dpText = `[${dpCode}]${dpName} 외 ${length - 1}건 선택됨`;
      setLookupText(dpText);
      setCostcenterCode(codes);
    } else {
      const dpText = selectedCostCenter
        .map((a, i) => `[${a.CostCenterCode}] ${a.CostCenterName}${i + 1 !== length ? ', ' : ''}`)
        .join('');
      setLookupText(dpText);
      setCostcenterCode(codes);
    }
  }, [selectedCostCenter]);

  const onPaymentDateCheckValueChanged = e => {
    setSearchData({
      ...searchData,
      ByPaymentDate: e.value ? 'Y' : 'N',
    });
  };

  const handleSalesMonth = e => {
    const yyyymmFormat = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setSearchData({
      ...searchData,
      SalesMonth: yyyymmFormat,
    });
  };

  const onSelectionChanged = e => {
    setCostcenterCode(e.selectedItem.CostCenterCode);
    setLookupText(e.selectedItem.CostCenterName);
  };

  const groupedData = new DataSource({
    store: costCenterType,
    key: 'CostCenterCode',
  });

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const fieldRender = () => {
    return (
      <div className="dx-lookup-field-wrapper">
        <div className={`dx-lookup-field ${!lookupText ? 'dx-lookup-empty' : ''}`}>
          {lookupText || '사업소를 선택하세요'}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>운영 보고서 출력</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={'/#/report/mail-send-history'}
            target="blank"
          >
            메일 전송 이력 조회
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
          <a
            className="manual-link"
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={profitDistributionReportManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'content-block customized-lookup'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={235}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 확정 매출 조회 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">등록월 :</div>,
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                width={100}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
              />,
              <Lookup
                labelMode="static"
                stylingMode="outlined"
                dataSource={groupedData}
                valueExpr="CostCenterCode"
                displayExpr="CostCenterName"
                searchExpr="CostCenterName"
                searchEnabled={true}
                dropDownCentered={false}
                width={400}
                placeholder="검색어를 입력해주세요"
                onSelectionChanged={onSelectionChanged}
                mode="search"
                fieldRender={fieldRender}
              >
                <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
              </Lookup>,
              <Button
                stylingMode="text"
                text="복수 선택"
                onClick={() => {
                  costcenterMasterRef.current.open();
                }}
              />,
            ]}
            headerAfter={[
              <CheckBox
                defaultValue={false}
                text="수금일 기준 조회   "
                onValueChanged={onPaymentDateCheckValueChanged}
              />,
              <div>{`(VAT 제외 비용)`}</div>,
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesMonth'} caption={'매출월'} alignment={'center'} width={'100'} />
            <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} width={'110'} />
            <Column caption={'매출'} alignment={'center'}>
              <Column dataField={'Sales-GeneralSales'} caption={'일반권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-SeasonTicket'} caption={'정기권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-DCTicket'} caption={'할인권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-EtcSales'} caption={'기타'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-BtoB'} caption={'BtoB'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-Total'} caption={'매출합계'} alignment={'left'} format="#,##0 원" />
            </Column>
            <Column caption={'운용비용'} alignment={'center'}>
              <Column dataField={'Cost-Fixed'} caption={'고정비'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Cost-Floating'} caption={'변동비'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Cost-Total'} caption={'비용합계'} alignment={'left'} format="#,##0 원" />
            </Column>
            <Column caption={'수익'} alignment={'center'}>
              <Column dataField={'Profit-Total'} caption={'수익합계'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Profit-User'} caption={'갑사수익'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Profit-Our'} caption={'자사수익'} alignment={'left'} format="#,##0 원" />
            </Column>
            {/* <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} /> */}
          </CommonDataGrid>
          <div style={{ display: 'flex', marginTop: '3px', width: '100%' }}>
            <div style={{ marginRight: '5px', width: '70%' }}>
              <ReportExport costData={costData} searchData={searchData} setLoadPanelVisible={setLoadPanelVisible} />
            </div>
            <div style={{ marginRight: '5px', width: '30%' }}>
              <CommonDataGrid
                gridRef={gridRef}
                className={'dx-card wide-card'}
                dataSource={dataExtra}
                showBorders={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={1000}
                isHeader={true}
                isExcel={false}
              >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
              </CommonDataGrid>
            </div>
          </div>
          <CostcenterMaster
            ref={costcenterMasterRef}
            setSelectedCostCenter={setSelectedCostCenter}
            isMulti
            isSalesCostReport
          />
          <PSLoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: '.content' }}
            onHiding={hideLoadPanel}
            visible={loadPanelVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewSalesCostReportPage;
