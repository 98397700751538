import axios from 'axios';

// 파일 저장 타입 (Upload, Download, Delete, Tag 등)
export const StorageType = {
  S3: 's3', // AWS S3
  NODE: 'node', // node fs 파일 저장
};
Object.freeze(StorageType);

// 파일 다운로드
export const readFile = async FileKey => {
  const FileDownload = require('js-file-download');
  const r1 = await axios.post(`/file/info`, { FileKey });
  const result = await axios.post(`/file/read`, { FileKey }, { responseType: 'blob' });
  FileDownload(result.data, r1.data.FileName);
};

// API 호출 주소 (외부 File 컴포넌트 등 별도 사용 목적)
const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const mainURL = isLocal ? 'http://localhost:3001/api/' : '/api/';

export const isEmptyObject = param =>
  param === null ||
  param === undefined ||
  (Object.keys(param).length === 0 && param.constructor === Object) ||
  Object.keys(param).length === 0;
export const isEmptyString = param => param === null || param === undefined || param === '';

export const excelSerialDateToJSDate = excelSerialDate => {
  const daysBeforeUnixEpoch = 70 * 365 + 19;
  const hour = 60 * 60 * 1000;
  return new Date(Math.round((excelSerialDate - daysBeforeUnixEpoch) * 24 * hour) + 12 * hour);
};

export const excelSerialDateToJSDatewTime = excelSerialDatewTime => {
  var excelSerialDate = Math.floor(excelSerialDatewTime);
  const daysBeforeUnixEpoch = 70 * 365 + 19;
  const hour = 60 * 60 * 1000;
  return new Date(Math.round((excelSerialDate - daysBeforeUnixEpoch) * 24 * hour) + 12 * hour);
};

export const secToHHmmss = excelSerialTime => {
  const toSec = excelSerialTime * 86400;
  function padValue(value) {
    return value < 10 ? '0' + value : value;
  }
  let hour = parseInt(toSec / 3600);
  hour = padValue(hour);
  let min = parseInt((toSec % 3600) / 60);
  min = padValue(min);
  let sec = parseInt(toSec % 60);
  sec = padValue(sec);
  return hour + ':' + min + ':' + sec;
};

export const sortByKor = (arr, key) => {
  const result = arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    } else if (a[key] > b[key]) {
      return 1;
    } else {
      return 0;
    }
  });
  return result;
};

export const getNormalDateFormat = value => {
  return value.replace(/\//g, '-');
};

export const autoFitColumnWidth = (worksheet, minimalWidth = 3, maxWidth = 70) => {
  worksheet.columns.forEach(column => {
    let maxColumnLength = 0;
    if (column && typeof column.eachCell === 'function') {
      column.eachCell({ includeEmpty: true }, cell => {
        if (cell.value) {
          const cellValue = cell.value.toString();
          let adjustedLength = 0;
          for (let char of cellValue) {
            adjustedLength += /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(char) ? 2 : 1;
          }
          maxColumnLength = Math.max(maxColumnLength, minimalWidth, adjustedLength);
        }
      });
      column.width = Math.min(maxColumnLength + 2, maxWidth);
    }
  });
  return worksheet;
};

export const isVerifiedDomain = email => {
  const allowedDomains = ['hiparking.co.kr'];
  const domain = email.split('@')[1];
  return allowedDomains.includes(domain);
};
