import React, { useState, useRef, useImperativeHandle, useCallback } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  CustomRule,
  RequiredRule,
} from 'devextreme-react/form';
import { confirm } from 'devextreme/ui/dialog';
import 'common/popup/popup.scss';

const EmailPopup = React.forwardRef((props, ref) => {
  const formRef = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [formData, setFormData] = useState({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
    close,
  }));

  const open = defaultData => {
    setFormData(defaultData);
    setIsPopup(true);
    setIsRefresh(!isRefresh);
  };

  const close = () => {
    setIsPopup(false);
  };

  const replaceSemicolonsWithCommas = str => {
    if (str.includes(';')) return str.replace(/;/g, ',');
    else return str;
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { RecipientEmail, CcEmail, BccEmail, SenderEmail } = formData;
      const tmpRecipientEmail = RecipientEmail && replaceSemicolonsWithCommas(RecipientEmail);
      const tmpCcEmail = CcEmail && replaceSemicolonsWithCommas(CcEmail);
      const tmpBccEmail = BccEmail && replaceSemicolonsWithCommas(BccEmail);
      const result = confirm('<i>메일을 전송하시겠습니까?</i>', '확인');
      result.then(dialogResult => {
        if (dialogResult) {
          props.onExporting('email', {
            SenderEmail,
            RecipientEmail: tmpRecipientEmail,
            CcEmail: tmpCcEmail,
            BccEmail: tmpBccEmail,
          });
          close();
        }
      });
    },
    [props.costData, formData],
  );

  const isValidEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validateEmailList = e => {
    const str = e.value;
    if (!str) return true;
    const arr = str.includes(';') ? str.split(';').map(email => email.trim()) : [str.trim()];
    const validEmails = arr.filter(Boolean);

    return validEmails.every(isValidEmail);
  };

  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="25%" height={380}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <div>
          <div className="popup-header">운영보고서 메일 발송</div>
          <i className="dx-icon-info" style={{ fontSize: '12px', marginLeft: '10px' }} id="title-help">
            &nbsp;메일 주소를 복수 입력하려면 <b>세미콜론</b>으로 구분하세요.<br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;메일 서명란의 발신자 표기는 Hi-DMS 유저정보의 '표기명'을 사용합니다.
          </i>
        </div>
        <div className="popup-content">
          <form onSubmit={onSubmit} className={`tax-detail-form`}>
            <Form
              ref={formRef}
              formData={formData}
              labelMode="outside"
              optionalMark="optional"
              stylingMode="outlined"
              labelLocation="left"
            >
              <SimpleItem
                dataField="SenderEmail"
                editorType="dxTextBox"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                  readOnly: true,
                }}
              >
                <Label text="발신자" />
              </SimpleItem>

              <SimpleItem
                dataField="RecipientEmail"
                editorType="dxTextBox"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                }}
              >
                <Label text="수신자" />
                <RequiredRule message="필수 값입니다." />
                <CustomRule message={'메일 주소가 올바르지 않습니다.'} validationCallback={validateEmailList} />
              </SimpleItem>
              <SimpleItem
                dataField="CcEmail"
                editorType="dxTextArea"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                }}
              >
                <Label text="참조" />
                <CustomRule message={'메일 주소가 올바르지 않습니다.'} validationCallback={validateEmailList} />
              </SimpleItem>
              {/* <SimpleItem
                dataField="BccEmail"
                editorType="dxTextBox"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                }}
              >
                <Label text="숨은참조" />
                <CustomRule message={'메일 주소가 올바르지 않습니다.'} validationCallback={validateEmailList} />
              </SimpleItem> */}

              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text="전송"></ButtonOptions>
                </ButtonItem>
                <ButtonItem horizontalAlignment="left">
                  <ButtonOptions width={'60%'} type={'normal'} text="닫기" onClick={close}></ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </Popup>
  );
});

export default EmailPopup;
