import React from 'react';
import { Column, Selection, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';

import moment from 'moment';
import CommonDataGrid from 'common/default-data-grid';

const SuspenseReceipt = props => {
  const { dataSource, setSRRowdata, susprcptRef } = props;
  const onSelectionChanged = e => {
    const result = susprcptRef.current.instance.getSelectedRowsData();
    setSRRowdata(result);
  };

  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };
  return (
    <>
      <CommonDataGrid
        gridRef={susprcptRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={500}
        onSelectionChanged={e => onSelectionChanged(e)}
        isHeader={true}
        isExcel={true}
        // loadPanel={{ enabled: true }}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소요청 가수금.xlsx',
        }}
        allowColumnReordering={true}
      >
        {/* <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" /> */}
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'ReceiptID'} caption={'가수금ID'} visible={false} />
        <Column dataField={'PaymentID'} caption={'결제ID'} visible={false} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={80} />
        <Column dataField={'TransactionDate'} caption={'결제일'} width={148} customizeText={customizeText} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} width={120} />
        <Column dataField={'PaymentCode'} caption={'수금구분'} width={120} />
        <Column dataField={'PaymentAgency'} caption={'결제사'} width={100} />
        <Column dataField={'TransactionAmount'} caption={'가수금금액'} format="#,##0 원" width={130} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={130} />
        <Column dataField={'RefundPrice'} caption={'환불금액'} format="#,##0 원" width={130} />
        <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" width={130} />
        <Column dataField={'CardType'} caption={'카드유형'} width={100} />
        <Column dataField={'Status'} caption={'상태'} width={100} />
        <Column dataField={'Remark'} caption={'비고'} width={120} />
        <Column dataField={'BankRemark'} caption={'입금자명'} width={120} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} width={90} />
        <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} width={148} />
        <Column dataField={'RegisterUser'} caption={'등록자'} />
        <Summary>
          <TotalItem column="IssueCreditCard" summaryType="count" displayFormat="총 {0}건" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(SuspenseReceipt);
