import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { PivotGrid, FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import './parking-cost-mgmt.scss';

const ParkingCostViewByMonth = props => {
  const { costData } = props;

  const customPivotDataSource = new PivotGridDataSource({
    fields: [
      {
        caption: '항목그룹',
        dataField: 'CostGroupCodeName',
        width: 150,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '항목',
        dataField: 'CostCodeName',
        width: 150,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '월',
        dataField: 'SalesMonth',
        width: 80,
        area: 'column',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '사용여부',
        dataField: 'UseYN',
        width: 60,
        dataType: 'string',
        summaryType: 'max',
        area: 'data',
      },
      {
        caption: '값',
        dataField: 'Value',
        width: 80,
        dataType: 'string',
        format: '#,##0',
        summaryType: 'max',
        area: 'data',
      },
    ],

    store: new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData;
      },
    }),
  });

  return (
    <div>
      <PivotGrid
        className={'dx-card wide-card payment-pivot'}
        dataSource={customPivotDataSource}
        showBorders={true}
        noDataText="조회된 데이터가 없습니다."
        showColumnTotals={false}
        showColumnGrandTotals={false}
        showRowTotals={false}
        showRowGrandTotals={false}
        hoverStateEnabled={true}
        isExcel={true}
        minWidth={1000}
        maxWidth="100%"
        height={660}
      >
        <FieldPanel visible={false} allowFieldDragging={false} showFilterFields={false} />
        <FieldChooser enabled={false} />
      </PivotGrid>
    </div>
  );
};

export default ParkingCostViewByMonth;
