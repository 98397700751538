import { React, useEffect, useMemo, useState, useRef, useId } from 'react';
import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';
import { Button } from 'devextreme-react/button';
import { autoFitColumnWidth, isVerifiedDomain } from 'utils/common';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';

import { createWorksheet1 } from './worksheet/ws1';
import { createWorksheet2 } from './worksheet/ws2';
import { createWorksheet3 } from './worksheet/ws3';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useAuth } from 'contexts/auth';

import EmailPopup from './email-popup';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

const ViewSalesCostReportExportPage = props => {
  const { costData, searchData, setLoadPanelVisible } = props;
  const { user } = useAuth();

  const gridsalesRef = useRef({});
  const gridcashRef = useRef({});
  const gridcardRef = useRef({});
  const previewRef = useRef({});
  const pivotgridRef = useRef({});
  const selectBoxRef = useRef(null);

  const DEFAULT_SENDER = 'hidmsadmin@hiparking.co.kr';

  useEffect(() => {
    setTimeout(() => {
      selectBoxRef.current?.instance?.focus();
    }, 500);
  }, []);

  const [pivotDataSource, setPivotDataSource] = useState();
  const [salesDataSource, setSalesDataSource] = useState();
  const [cashDataSource, setCashDataSource] = useState();
  const [cardDataSource, setCardsDataSource] = useState();

  useMemo(() => {
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData[1];
      },
    });
    const customCashDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData[6];
      },
    });
    const customCardDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData[7];
      },
    });
    const customPivotDataSource = new PivotGridDataSource({
      fields: [
        {
          caption: '구분1',
          dataField: '구분1',
          width: 100,
          area: 'row',
          expanded: true,
          sortBy: 'none',
        },
        {
          caption: '구분2',
          dataField: '구분2',
          width: 100,
          area: 'row',
          expanded: true,
          sortBy: 'none',
        },
        {
          caption: '구분3',
          dataField: '구분3',
          width: 100,
          area: 'row',
          expanded: true,
          sortBy: 'none',
          sortingMethod: function (a, b) {
            return a.DetailSort - b.DetailSort; // '순서' 필드를 기준으로 정렬
          },
        },

        {
          caption: '실적',
          dataField: '실적',
          width: 200,
          dataType: 'number',
          format: '#,##0',
          summaryType: 'sum',
          area: 'data',
        },
        {
          caption: '산출근거',
          dataField: '산출근거',
          width: 300,
          dataType: 'string',
          summaryType: 'max',
          area: 'data',
        },
      ],

      store: new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[2];
        },
      }),
    });
    setPivotDataSource(customPivotDataSource);
    setSalesDataSource(customDataSource);
    setCashDataSource(customCashDataSource);
    setCardsDataSource(customCardDataSource);
  }, [costData]);
  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };

  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };

  const onExporting = async (Invoker, formData) => {
    if (!costData || costData[3].length === 0) {
      return alert('사업소를 선택해주세요.');
    }
    const workbook = new Workbook();
    const sCostCenterName = costData[3][0]['영업점명'];
    const sCostCenterCode = costData[3][0]['영업점코드'];

    const worksheet1 = workbook.addWorksheet('갑지');
    await createWorksheet1(workbook, worksheet1, pivotDataSource, costData);

    const worksheet2 = workbook.addWorksheet('매출');
    await createWorksheet2(worksheet2, gridsalesRef, costData);
    if (Invoker === 'pdf') {
      worksheet2.state = 'hidden';
    }
    const worksheet3 = workbook.addWorksheet('지출');
    await createWorksheet3(workbook, worksheet3, pivotDataSource, pivotgridRef, costData);

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const fileNameXlsx = `수배정산서_${sCostCenterName}_${searchData.SalesMonth}.xlsx`;
      const fileNamePdf = `수배정산서_${sCostCenterName}_${searchData.SalesMonth}.pdf`;

      if (Invoker === 'email') {
        const { RecipientEmail, BccEmail, CcEmail, SenderEmail } = formData;
        const base64Buffer = buffer.toString('base64');
        const res = await axios.post('aggregation/sendEmailWithAttachment', {
          CostCenterCode: sCostCenterCode,
          CostCenterName: sCostCenterName,
          SalesMonth: searchData.SalesMonth,
          SenderEmail,
          RecipientEmail,
          CcEmail,
          BccEmail,
          FileBuffer: base64Buffer,
          FileName: fileNameXlsx,
          SignitureInfo: user,
        });
        if (res.status === 200) {
          notify(
            {
              message: '메일이 전송되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          handleSaveAsFile(buffer, fileNameXlsx);
        }
      } else if (Invoker === 'pdf') {
        setLoadPanelVisible(true);
        const response = await axios.post('aggregation/convertExcelToPdf', buffer, {
          headers: {
            'Content-Type': 'application/octet-stream', // 바이너리 데이터
          },
          responseType: 'arraybuffer',
        });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = fileNamePdf;
        link.click();

        window.URL.revokeObjectURL(pdfUrl);

        setLoadPanelVisible(false);
      } else {
        handleSaveAsFile(buffer, fileNameXlsx);
      }
    } catch (error) {
      setLoadPanelVisible(false);
      notify(
        {
          message: error,
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const onPaymentExporting = async () => {
    if (!costData || !costData[6]) {
      return alert('보고서 데이터가 없습니다.');
    }

    const workbook = new Workbook();
    const worksheet1 = workbook.addWorksheet('계좌');
    const worksheet2 = workbook.addWorksheet('카드');

    // const sCostCenterCode = costData[0][0]['HiParkingID'];
    const sCostCenterName = costData[0][0]['CostCenterName'];
    // 열의 기본 너비 설정 (예: 15)

    const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };

    try {
      const nCashLength = costData[6].length;
      // 가상계좌 시트
      const exporGridResultCash = exportDataGrid({
        component: gridcashRef.current.instance,
        worksheet: worksheet1,
        topLeftCell: { row: 2, column: 1 },
        customizeCell: options => {
          if (options.gridCell.column.dataType === 'number') {
            options.excelCell.style = { numFmt: '#,##0' };
          }
          if (options.gridCell.rowType === 'header') {
            options.excelCell.fill = fillGrey;
          }
          options.excelCell.font = { name: '맑은 고딕', size: 10 };
          options.excelCell.alignment = { wrapText: true };
          options.excelCell.border = borderAll;
        },
        autoFilterEnabled: true,
      });
      await exporGridResultCash;

      worksheet1.getColumn('B').alignment = alignmentCenter;
      worksheet1.getCell('A1').alignment = alignmentLeft;
      worksheet1.getRow(2).alignment = alignmentCenter;
      worksheet1.getCell('A1').value = {
        richText: [{ text: `계좌`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
      };

      worksheet1.getCell(`A${nCashLength + 3}`).value = '합계';
      if (nCashLength > 0) {
        worksheet1.getCell(`D${nCashLength + 3}`).value = { formula: `SUM(D3:D${nCashLength + 2})` };
      }
      worksheet1.getCell(`A${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`B${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`C${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`D${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`E${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`F${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`G${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`H${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`I${nCashLength + 3}`).fill = fillGrey;
      worksheet1.getCell(`J${nCashLength + 3}`).fill = fillGrey;
      worksheet1.pageSetup.scale = 54;
      autoFitColumnWidth(worksheet1);
      worksheet1.getColumn(1).width = 8;

      const nCardLength = costData[7].length;
      // 카드 시트
      const exporGridResultCard = exportDataGrid({
        component: gridcardRef.current.instance,
        worksheet: worksheet2,
        topLeftCell: { row: 2, column: 1 },
        customizeCell: options => {
          if (options.gridCell.column.dataType === 'number') {
            options.excelCell.style = { numFmt: '#,##0' };
          }
          if (options.gridCell.rowType === 'header') {
            options.excelCell.fill = fillGrey;
          }
          options.excelCell.font = { name: '맑은 고딕', size: 10 };
          options.excelCell.alignment = { wrapText: true };
          options.excelCell.border = borderAll;
        },
        autoFilterEnabled: true,
      });
      await exporGridResultCard;

      worksheet2.getColumn('B').alignment = alignmentCenter;
      // worksheet2.getColumn('C').alignment = alignmentCenter;
      worksheet2.getCell('A1').alignment = alignmentLeft;
      worksheet2.getRow(2).alignment = alignmentCenter;
      worksheet2.getCell('A1').value = {
        richText: [{ text: `카드조회`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
      };
      worksheet2.getCell(`A${nCardLength + 3}`).value = '합계';
      if (nCardLength > 0) {
        worksheet2.getCell(`F${nCardLength + 3}`).value = { formula: `SUM(F3:F${nCardLength + 2})` };
        worksheet2.getCell(`G${nCardLength + 3}`).value = { formula: `SUM(G3:G${nCardLength + 2})` };
        worksheet2.getCell(`H${nCardLength + 3}`).value = { formula: `SUM(H3:H${nCardLength + 2})` };
        worksheet2.getCell(`I${nCardLength + 3}`).value = { formula: `SUM(I3:I${nCardLength + 2})` };
        worksheet2.getCell(`J${nCardLength + 3}`).value = { formula: `SUM(J3:J${nCardLength + 2})` };
        worksheet2.getCell(`K${nCardLength + 3}`).value = { formula: `SUM(K3:K${nCardLength + 2})` };
      }
      worksheet2.getCell(`A${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`B${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`C${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`D${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`E${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`F${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`G${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`H${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`I${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`J${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`K${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`L${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`M${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`N${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`O${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`P${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`Q${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`R${nCardLength + 3}`).fill = fillGrey;
      worksheet2.getCell(`S${nCardLength + 3}`).fill = fillGrey;
      worksheet2.pageSetup.scale = 54;
      autoFitColumnWidth(worksheet2);
      worksheet2.getColumn(1).width = 8;

      const buffer = await workbook.xlsx.writeBuffer();
      const fileNameXlsx = `계좌카드내역_${sCostCenterName}_${searchData.SalesMonth}.xlsx`;

      handleSaveAsFile(buffer, fileNameXlsx);
    } catch (error) {
      notify(
        {
          message: error,
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const handleSaveAsFile = (buffer, fileNameXlsx) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileNameXlsx);
  };

  const onPdfClick = async () => {
    onExporting('pdf');
  };

  const onEmailClick = async () => {
    if (!costData || costData[3].length === 0) {
      return alert('사업소를 선택해주세요.');
    }
    const defaultData = {};
    defaultData.SenderEmail = isVerifiedDomain(user.Email) ? user.Email : DEFAULT_SENDER;
    defaultData.RecipientEmail = costData[3][0]['이메일 수신인'];
    defaultData.CcEmail = costData[3][0]['이메일 참조인'];
    defaultData.BccEmail = costData[3][0]['이메일 숨은참조인'];
    previewRef.current.open(defaultData);
  };

  return (
    <div>
      <Toolbar>
        <Item location={'before'}>
          <h5 style={{ margin: 0 }}>{`운영 보고서 출력`}</h5>
        </Item>
        <Item location={'after'}>
          <Button icon="email" onClick={onEmailClick} hint="수배정산서 이메일 전송" />
          <Button icon="exportpdf" onClick={onPdfClick} hint="PDF 추출" />
          <Button icon="exportxlsx" onClick={onExporting} hint="수배정산서 Excel 다운로드" />
          <Button icon="menu" onClick={onPaymentExporting} hint="카드/계좌 내역 Excel 다운로드" />
        </Item>
      </Toolbar>
      <PivotGrid
        ref={pivotgridRef}
        className={'dx-card wide-card payment-pivot'}
        dataSource={pivotDataSource}
        showBorders={true}
        showColumnTotals={true}
        showRowTotals={true}
        showRowGrandTotals={false}
        hoverStateEnabled={true}
        height={1000}
        onExporting={onExporting}
      >
        <Scrolling mode="virtual" showScrollbar="always" />
        <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
        <FieldChooser enabled={false} />
      </PivotGrid>
      <div>
        <CommonDataGrid
          gridRef={gridsalesRef}
          className={'dx-card wide-card'}
          dataSource={salesDataSource}
          showBorders={true}
          hoverStateEnabled={true}
          stylingMode="outlined"
          isExcel={false}
          isHeader={true}
          visible={false}
          height={500}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <Column dataField={'순번'} width={'40'} />
          <Column dataField={'영업점'} />
          <Column dataField={'고객/ 거래처'} />
          <Column dataField={'매출 항목'} />
          <Column dataField={'매출 일자'} />
          <Column dataField={'매출 수량'} />
          <Column dataField={'합계 금액'} valueFormat="#,##0" width={'100'} />
          <Column dataField={'공급가액'} valueFormat="#,##0" width={'100'} />
          <Column dataField={'부가세'} valueFormat="#,##0" width={'100'} />
          <Column dataField={'수금 구분'} />
          <Column dataField={'매출 상세 구분'} />
          <Summary>
            <TotalItem column="매출 수량" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="합계 금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="공급가액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="부가세" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
          </Summary>
        </CommonDataGrid>
        <CommonDataGrid
          gridRef={gridcashRef}
          className={'dx-card wide-card'}
          dataSource={cashDataSource}
          showBorders={true}
          hoverStateEnabled={true}
          stylingMode="outlined"
          isExcel={false}
          isHeader={true}
          visible={false}
          height={500}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <Column dataField={'순번'} width={'40'} />
          <Column dataField={'가맹점명'} />
          <Column dataField={'입금일시'} />
          <Column dataField={'승인금액'} />
          <Column dataField={'계좌번호'} />
          <Column dataField={'은행명'} />
          <Column dataField={'거래번호'} />
          <Column dataField={'주문번호'} />
          <Column dataField={'가맹점ID'} />
          <Column dataField={'입금자명'} />
          <Summary>
            {/* <TotalItem column="가맹점명" summaryType="count" valueFormat="#,##" displayFormat="{0}" /> */}
            <TotalItem column="승인금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
          </Summary>
        </CommonDataGrid>
        <CommonDataGrid
          gridRef={gridcardRef}
          className={'dx-card wide-card'}
          dataSource={cardDataSource}
          showBorders={true}
          hoverStateEnabled={true}
          stylingMode="outlined"
          isExcel={false}
          isHeader={true}
          visible={false}
          height={500}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <Column dataField={'순번'} width={'40'} />
          <Column dataField={'상태'} />
          <Column dataField={'가맹점명'} />
          <Column dataField={'승인일시'} />
          <Column dataField={'취소일시'} />
          <Column dataField={'승인금액'} />
          <Column dataField={'취소금액'} />
          <Column dataField={'PG수수료'} />
          <Column dataField={'수수료'} />
          <Column dataField={'부가세'} />
          <Column dataField={'정산금액'} />
          <Column dataField={'카드번호'} />
          <Column dataField={'승인번호'} />
          <Column dataField={'할부개월'} />
          <Column dataField={'발급사'} />
          <Column dataField={'매입사'} />
          <Column dataField={'거래번호'} />
          <Column dataField={'주문번호'} />
          <Column dataField={'가맹점ID'} />
          <Summary>
            {/* <TotalItem column="승인일시" summaryType="count" valueFormat="#,##" displayFormat="{0}" /> */}
            <TotalItem column="승인금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="취소금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="PG수수료" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="수수료" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="부가세" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
            <TotalItem column="정산금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
          </Summary>
        </CommonDataGrid>
      </div>
      <EmailPopup ref={previewRef} onExporting={onExporting} costData={costData} />
    </div>
  );
};

export default ViewSalesCostReportExportPage;
