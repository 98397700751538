import { Column, Editing, Scrolling, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { NumberBox } from 'devextreme-react';
import CheckBox from 'devextreme-react/check-box';
import { Tooltip } from 'devextreme-react/tooltip';

const CostGrid = forwardRef((props, ref) => {
  const { title, height, dsCost, help, imageSource, keyID, useCustomLabel } = props;
  const FIXED = title === '고정경비(B)';
  const FLOATING = title === '변동경비(B)';

  const gridRef = useRef({});

  const [dataSource, setDataSource] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    getDataSource,
  }));

  const getDataSource = () => {
    return dataSource;
  };

  useEffect(() => {
    setDataSource(dsCost);
  }, [dsCost]);

  const onChangesChange = e => {
    console.log('onChangesChange...');
    if (e.length > 0) {
      gridRef.current.instance.saveEditData();
    }
  };

  /////////////////////////////////////////////////
  // const renderGridCellExpense = data => {
  //   console.log(data);
  //   return <div style={{ color: 'blue' }}>{data.text}</div>;
  // };

  const onValueChangedCell = (cell, e) => {
    console.log(cell);
    console.log(e);
    // cell.setValue(e.value);
  };

  const expenseEditorRender = cell => {
    // const onValueChanged = this.onValueChanged.bind(this, cell);
    return <NumberBox defaultValue={cell.value} onValueChanged={onValueChangedCell} stylingMode={'outlined'} />;
  };

  const renderUseYN = () => {
    const onSelectAllChange = e => {
      if (e.event) {
        const updatedDataSource = dataSource.map(a => {
          return { ...a, UseYN: isChecked };
        });
        setDataSource(updatedDataSource);
        setIsChecked(!isChecked);
      }
    };
    return (
      <CheckBox value={dataSource.length > 0 && dataSource.every(a => a.UseYN)} onValueChanged={onSelectAllChange} />
    );
  };

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card minimal-padding'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        height={height}
        isHeader={true}
        isExcel={false}
        excelOptions={{}}
        allowColumnReordering={true}
        headerBefore={[
          <div className={'cost-title'}>{title}</div>,
          keyID && (
            <i
              id={'icon' + keyID}
              className="dx-icon dx-icon-help help-cursor"
              style={{ fontSize: '16px', paddingTop: '9px', paddingLeft: '0px' }}
            ></i>
          ),
          <div style={{ paddingTop: '10px', fontStyle: 'italic' }}>{help}</div>,
        ]}
        headerAfter={[]}
        isNotFilterRow
      >
        <Editing
          mode="cell"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={true}
          onChangesChange={onChangesChange}
        />
        <Scrolling mode="virtual" rowRenderingMode="virtual" useNative={true} />
        <Column
          dataField={'UseYN'}
          dataType="boolean"
          width={40}
          allowReordering={false}
          allowResizing={false}
          allowSorting={false}
          allowFiltering={false}
          headerCellRender={renderUseYN}
        />
        <Column dataField={'CostDetailName'} caption={'세부구분'} width={105} allowEditing={false} />
        {useCustomLabel && <Column dataField={'ExtraField1'} dataType="string" caption={'별칭지정'} width={105} />}
        <Column
          dataField={'Value'}
          width={100}
          caption={FLOATING ? '금액(원)/입력값' : '금액(원)'}
          dataType="number"
          format={FLOATING ? '#,###.##' : '#,##0'}
          alignment="right"
        />
        <Column
          dataField={'Unit'}
          caption={' '}
          width={20}
          allowEditing={false}
          alignment="center"
          allowFiltering={false}
          allowReordering={false}
        />
        <Column dataField={'참고용'} caption={'참고'} allowEditing={false} alignment="left" />
        {FIXED && (
          <Summary>
            <TotalItem column="Value" displayFormat="합계: " />
            <TotalItem column="Value" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
          </Summary>
        )}
      </CommonDataGrid>
      <Tooltip target={'#icon' + keyID} showEvent="mouseenter" hideEvent="mouseleave" position="left">
        <div id="tooltip-content" style={{ textAlign: 'left', fontSize: '13px' }}>
          <img width="550" height="590" alt="이미지없음" src={imageSource}></img>
        </div>
      </Tooltip>
    </>
  );
});

export default CostGrid;
