import React, { useState, useRef, useMemo, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';

import { cellStatus4Cashbill } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { Column, Scrolling, LoadPanel } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import 'styles/CommonStyles.scss';

const MailSendHistory = () => {
  const detailHistoryRef = useRef({});
  const searchIdentifierRef = useRef({});
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchText: '',
  });

  const [refresh, setRefresh] = useState(false);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };
  const onChangeSearchText = e => {
    setSearchData({ ...searchData, SearchText: e.value });
  };

  const onEnterKey = e => {
    setSearchData({
      ...searchData,
    });
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'Idx',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`report/getMailSendHistory`, {
            ...searchData,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });
    return customDataSource;
  }, [searchData, refresh]);

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus4Cashbill(data, displayValue, cellElement);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeStatusText = cellInfo => {
    if (cellInfo.value === 'Y') return '전송완료';
    else return '실패';
  };

  const onRefreshClick = () => {
    setRefresh(!refresh);
  };

  const statusCellRender = e => {
    const { SendYN } = e.data;

    if (SendYN === 'Y') {
      return <div className="Badge badge-status-blue">전송완료</div>;
    }
    if (SendYN === 'N') {
      return <div className="Badge badge-status-red">실패</div>;
    }
  };
  return (
    <React.Fragment>
      <h2 className={'content-block detail-title'}>
        이메일 전송 이력 조회
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={detailHistoryRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={dataSource}
            showBorders={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            wordWrapEnabled={true}
            hoverStateEnabled={true}
            height={670}
            onCellPrepared={onCellPrepared}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '현금영수증 발행 전체 목록.xlsx',
              customizeCell: {
                date: ['RegisterDate', 'LastUpdateDate'],
              },
            }}
            headerBefore={[
              <div className="label"> 발송일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />,
              <div className="label">~</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TextBox
                ref={searchIdentifierRef}
                label="searchText"
                labelMode="hidden"
                stylingMode="outlined"
                placeholder="사업소명/사업소코드"
                onValueChanged={onChangeSearchText}
                onEnterKey={onEnterKey}
                width={220}
                mode="search"
              />,
            ]}
            headerAfter={[<Button icon="refresh" type="normal" stylingMode="contained" onClick={onRefreshClick} />]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" useNative={true} />
            <LoadPanel enabled={false} />

            <Column dataField="RegisterDate" caption="발송일" width={180} customizeText={customizeText} />
            <Column
              dataField="SendYN"
              caption="상태"
              width={100}
              cellRender={statusCellRender}
              alignment="center"
              customizeText={customizeStatusText}
            />
            <Column dataField="MailTypeName" caption="메일 구분" width={120} />
            <Column dataField="CostCenterName" caption="(대표)사업소명" width={180} />
            <Column dataField="MailTitle" caption="메일명" />
            <Column dataField="SenderEmail" caption="발신자" width={200} />
            <Column dataField="RecipientEmail" caption="수신자" width={200} />
            <Column dataField="RegisterUser" caption="등록자" width={100} alignment="center" />
          </CommonDataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(MailSendHistory);
