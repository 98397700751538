import React, { useCallback, useState } from 'react';
import moment from 'moment';
import DataGrid, {
  Column,
  Selection,
  HeaderFilter,
  Summary,
  TotalItem,
  MasterDetail,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import TaxinvoiceDetailSales from './component/taxinvoice-detail-sales';

const Tax = props => {
  const { dataSource, taxinvoiceRef, setTRowdata, tGeneralTicketRef, tSeasonTicketRef, tEtcRef, tB2BRef } = props;

  const [selectTax, setSelectTax] = useState({});
  const onSelectionChanged = e => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
    const selectionAllData = taxinvoiceRef.current?.instance?.getSelectedRowsData();
    setTRowdata(selectionAllData);
    setSelectTax(e.currentSelectedRowKeys);
  };

  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };
  const onRowExpanded = e => {
    const selectedRowKey = e.key;
    taxinvoiceRef.current.instance.selectRows([selectedRowKey], false);
  };

  const renderDetailSection = useCallback(({ data }) => {
    return (
      <TaxinvoiceDetailSales
        data={{ data: data }}
        tGeneralTicketRef={tGeneralTicketRef}
        tSeasonTicketRef={tSeasonTicketRef}
        tEtcRef={tEtcRef}
        tB2BRef={tB2BRef}
        selectTax={selectTax}
      />
    );
  }, []);

  return (
    <React.Fragment>
      <CommonDataGrid
        gridRef={taxinvoiceRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={600}
        onSelectionChanged={onSelectionChanged}
        onRowExpanded={onRowExpanded}
        isHeader={true}
        isInfiniteScroll={false}
        isExcel={true}
        // loadPanel={{ enabled: true }}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소요청 세금계산서.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={false} />
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={80} />
        <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
        <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
        <Column dataField={'PaymentDate'} caption={'결제일'} customizeText={customizeText} width={100} />
        <Column dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
        <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} width={100} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={100} />
        <Column dataField={'SalesPrice'} caption={'금액'} width={160} format="#,##0 원" />
        <Column dataField={'CustomerName'} caption={'사업자명'} width={200} />
        <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
        <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
        <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
        <Column dataField={'SalesCount'} caption={'품목 수'} width={80} />
        <Column dataField={'TaxPrice'} caption={'부가세'} width={90} format="#,##0 원" />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={100} format="#,##0 원" />
        <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} />
        <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} />
        <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
        <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
        <Column dataField={'RegisterUser'} caption={'등록자'} width={80} />
        <Column dataField={'RegisterDate'} caption={'등록일'} width={120} customizeText={customizeText} />
        <Column dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} />
        <MasterDetail enabled={true} render={renderDetailSection} />
        <Summary>
          <TotalItem column="InvoiceID" summaryType="count" displayFormat="총 {0}건" />
        </Summary>
      </CommonDataGrid>
    </React.Fragment>
  );
};

export default React.memo(Tax);
