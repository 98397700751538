import React from 'react';
import { Column, Selection, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import moment from 'moment';
import CommonDataGrid from 'common/default-data-grid';

const GeneralTicket = props => {
  const { setGRowdata, generalTicketRef, dataSource } = props;

  const onSelectionChanged = e => {
    const result = generalTicketRef.current.instance.getSelectedRowsData();
    setGRowdata(result);
  };

  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };

  return (
    <>
      <CommonDataGrid
        gridRef={generalTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={500}
        onSelectionChanged={e => onSelectionChanged(e)}
        isHeader={true}
        isExcel={true}
        loadPanel={{ enabled: false }}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소요청 일반권.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={150} />
        <Column dataField={'ApprovalDate'} caption={'매출시간'} customizeText={customizeText} width={150} />
        <Column dataField={'PaymentDate'} caption={'결제일'} customizeText={customizeText} width={150} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={90} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} width={120} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={150} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={150} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={150} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={150} />
        <Column dataField={'PaymentCodeName'} caption={'결제유형'} width={90} />
        <Column dataField={'Remark'} caption={'비고'} width={150} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={90} />
        <Column dataField={'CardAgencyListName'} caption={'결제사'} />
        <Summary>
          <TotalItem column="IssueCreditCard" summaryType="count" displayFormat="총 {0}건" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(GeneralTicket);
