import moment from 'moment';
import { underlineImage, hiparkingImage } from '../base64';

export async function createWorksheet1(workbook, worksheet1, pivotDataSource, costData) {
  const getValueFromTable = (costData, searchCode) => {
    const targetRow = costData.find(row => row.CostDetailCode === searchCode);
    return targetRow ? targetRow.Value : null;
  };
  const getNameFromTable = (costData, searchCode) => {
    const targetRow = costData.find(row => row.CostDetailCode === searchCode);
    return targetRow ? targetRow.CodeDesc : null;
  };
  const getCustomNameFromTable = (costData, searchCode) => {
    const targetRow = costData.find(row => row.CostDetailCode === searchCode);
    return targetRow ? targetRow.ExtraField1 : null;
  };

  const MergeCellsWithStyle = (worksheet, range) => {
    worksheet.unMergeCells(range);
    worksheet.mergeCells(range);
    worksheet.getCell(`${range.split(':')[0]}`).border = borderAll;
    worksheet.getCell(`${range.split(':')[0]}`).alignment = alignmentCenter;
  };

  function findFixedExpenseObject(node, searchString) {
    if (node.value === searchString) {
      return node;
    }
    for (const child of node.children) {
      const result = findFixedExpenseObject(child, searchString);
      if (result) {
        return result;
      }
    }
    return null;
  }

  const sCostCenterName = costData[3][0]['영업점명'];
  // 열의 기본 너비 설정 (예: 15)

  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };
  const fillYellow = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFBF900' } };

  const pivotraw = await pivotDataSource.load().done();
  const nodeSales = findFixedExpenseObject(pivotraw.rows[0], '매출');
  const nodeCostFixed = pivotraw.rows.length > 1 ? findFixedExpenseObject(pivotraw.rows[1], '고정경비') : null;
  const nodeCostFloating = pivotraw.rows.length > 1 ? findFixedExpenseObject(pivotraw.rows[1], '변동경비') : null;
  const imageId1 = workbook.addImage({
    base64: underlineImage,
    extension: 'png',
  });
  const imageId2 = workbook.addImage({
    base64: hiparkingImage,
    extension: 'png',
  });

  const rnumFirst = 7;
  const cntSales = nodeSales.children.length;
  const cntCostFixed = nodeCostFixed ? nodeCostFixed.children.length : 0;
  const cntCostFloating = nodeCostFloating ? nodeCostFloating.children.length : 0;
  const cntCostTotal = cntCostFixed + cntCostFloating;
  const rnumLastRow =
    rnumFirst + 1 + cntSales + 1 + cntCostTotal + (cntCostFixed > 0 ? 1 : 0) + (cntCostFloating > 0 ? 1 : 0);

  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };

  const alignmentRight = { vertical: 'middle', horizontal: 'right' };
  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };
  const alignmentLeftShrinkText = { vertical: 'middle', horizontal: 'left', shrinkToFit: true };
  const alignmentCenterShrinkText = { vertical: 'middle', horizontal: 'center', shrinkToFit: true };
  const wrapTextAlignment = {
    wrapText: true,
    vertical: 'middle',
    horizontal: 'center',
  };

  worksheet1.columns = [
    { width: 1 },
    { width: 14 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 1 },
  ];
  worksheet1.getRow(1).height = 10;
  worksheet1.getRow(2).height = 35;
  worksheet1.getRow(3).height = 5;
  for (let r = 5; r <= 27; r++) {
    worksheet1.getRow(r).height = 18;
  }

  worksheet1.addImage(imageId1, 'R2:V2');
  worksheet1.addImage(imageId2, 'B3:V3');

  worksheet1.getCell('B2').value = {
    richText: [
      {
        text: `${sCostCenterName} 주차장 운영 보고서`,
        font: { size: 20, name: '휴먼둥근헤드라인' },
      },
    ], // 휴먼둥근헤드라인
  };

  worksheet1.mergeCells('B2:Q2');
  worksheet1.mergeCells('Q10:V10');
  worksheet1.mergeCells('R16:V16');
  worksheet1.mergeCells('R22:V22');
  worksheet1.getCell('B2').alignment = alignmentCenterShrinkText;

  let nTitleNum = 1;
  let nSectionNum = 1;
  // merge 대상..
  const sheet1Ranges = [
    'B5:B5',
    'C5:J5',
    'K5:N5',
    'O5:V5',
    'B6:B6',
    'C6:J6',
    'K6:N6',
    'O6:V6',
    'B7:B7',
    'C7:V7',
    'B8:B8',
    'C8:J8',
    'K8:N8',
    'O8:V8',

    'B18:B18',
    'C18:G18',
    'H18:L18',
    'M18:Q18',
    'R18:V18',
    'B19:B19',
    'C19:G19',
    'H19:L19',
    'M19:Q19',
    'R19:V19',
    'B22:B22',
    'C22:G22',
    'H22:L22',
    'M22:Q22',
    'R22:V22',
    'B23:B23',
    'C23:G23',
    'H23:L23',
    'M23:Q23',
    'R23:V23',
    'B26:B26',
    'C26:G26',
    'H26:L26',
    'M26:Q26',
    'R26:V26',
    'B27:B27',
    'C27:G27',
    'H27:L27',
    'M27:Q27',
    'R27:V27',

    'B11:B11',
    'C11:F11',
    'G11:J11',
    'K11:N11',
    'O11:R11',
    'S11:V11',
    'B12:B12',
    'C12:F12',
    'G12:J12',
    'K12:N12',
    'O12:R12',
    'S12:V12',
    'B13:B13',
    'C13:F13',
    'G13:J13',
    'K13:N13',
    'O13:R13',
    'S13:V13',
    'B14:B14',
    'C14:F14',
    'G14:J14',
    'K14:N14',
    'O14:R14',
    'S14:V14',
  ];
  sheet1Ranges.forEach(range => {
    worksheet1.unMergeCells(range);
    worksheet1.mergeCells(range);
    worksheet1.getCell(`${range.split(':')[0]}`).border = borderAll;
    worksheet1.getCell(`${range.split(':')[0]}`).alignment = alignmentCenter;
  });

  const sStartDate = costData[3][0]['시작일'];
  const sEndDate = costData[3][0]['종료일'];
  const sParkingArea = costData[3][0]['주차면수'];
  const sAddress = costData[3][0]['주소'];
  const sCustomerName = costData[3][0]['수신처'];
  const sIsTaxFree = costData[3][0]['부가세없음'];
  const sIsDeficitCoverage = costData[3][0]['적자보전'];
  const sProfitOur = costData[3][0]['배분율-자사'];
  const sProfitUser = costData[3][0]['배분율-갑사'];
  const sSalesDeduction =
    costData[3][0]['매출차감금액'] > '' ? parseInt(costData[3][0]['매출차감금액'].replace(/,/g, '')) : 0;
  const sFixedRentalAmount = parseInt(getValueFromTable(costData[4], 'FixedRentalAmount'));
  const sFixedRentalCustomName = getCustomNameFromTable(costData[4], 'FixedRentalAmount');
  const sFixedRentalAmountName = sFixedRentalCustomName || getNameFromTable(costData[4], 'FixedRentalAmount');

  const sParkingSubsidyAmount = parseInt(getValueFromTable(costData[4], 'ParkingSubsidyAmount'));
  const sParkingSubsidyAmountCustomName = getCustomNameFromTable(costData[4], 'ParkingSubsidyAmount');
  const sParkingSubsidyAmountName =
    sParkingSubsidyAmountCustomName || getNameFromTable(costData[4], 'ParkingSubsidyAmount');

  const sCharge001 = parseInt(getValueFromTable(costData[5], '001'));
  const sCharge001CustomName = getCustomNameFromTable(costData[5], '001');
  const sCharge001Name = sCharge001CustomName || getNameFromTable(costData[5], '001');

  const sCharge002 = parseInt(getValueFromTable(costData[5], '002'));
  const sCharge002CustomName = getCustomNameFromTable(costData[5], '002');
  const sCharge002Name = sCharge002CustomName || getNameFromTable(costData[5], '002');

  const sCharge003 = parseInt(getValueFromTable(costData[5], '003'));
  const sCharge003CustomName = getCustomNameFromTable(costData[5], '003');
  const sCharge003Name = sCharge003CustomName || getNameFromTable(costData[5], '003');

  let bIsDeficit = false;

  const tempSalesTotal = costData[2].reduce((acc, obj) => {
    const value = parseInt(obj.실적);
    return obj.구분1 !== '원가' ? acc + value : acc;
  }, 0);
  const tempCostTotal = costData[2].reduce((acc, obj) => {
    const value = parseInt(obj.실적);
    return obj.구분1 === '원가' ? acc + value : acc;
  }, 0);
  bIsDeficit = tempSalesTotal - tempCostTotal - sSalesDeduction < 0;

  worksheet1.getCell('B5').value = '보고일자';
  worksheet1.getCell('B5').fill = fillGrey;
  worksheet1.getCell('C5').value = moment(new Date()).format('yyyy-MM-DD');
  worksheet1.getCell('C5').alignment = alignmentLeft;
  worksheet1.getCell('K5').value = '대상기간';
  worksheet1.getCell('K5').fill = fillGrey;
  worksheet1.getCell('O5').value = sStartDate + ' ~ ' + sEndDate;
  worksheet1.getCell('O5').alignment = alignmentLeft;
  worksheet1.getCell('B6').value = '영업점명';
  worksheet1.getCell('B6').fill = fillGrey;
  worksheet1.getCell('C6').value = sCostCenterName;
  worksheet1.getCell('C6').alignment = alignmentLeft;
  worksheet1.getCell('C6').alignment = alignmentLeftShrinkText;
  worksheet1.getCell('K6').value = '주차면수';
  worksheet1.getCell('K6').fill = fillGrey;
  worksheet1.getCell('O6').value = sParkingArea;
  worksheet1.getCell('O6').alignment = alignmentLeft;
  worksheet1.getCell('B7').value = '소재지';
  worksheet1.getCell('B7').fill = fillGrey;
  worksheet1.getCell('C7').value = sAddress;
  worksheet1.getCell('C7').alignment = alignmentLeft;
  worksheet1.getCell('C8').value = sCustomerName;
  worksheet1.getCell('C8').alignment = alignmentLeftShrinkText;
  worksheet1.getCell('B8').value = '수신처';
  worksheet1.getCell('B8').fill = fillGrey;
  worksheet1.getCell('M8').value = '발신인';
  worksheet1.getCell('M8').fill = fillGrey;
  worksheet1.getCell('Q8').value = '하이파킹 매출관리팀';
  worksheet1.getCell('Q8').alignment = alignmentLeft;

  worksheet1.getCell('B10').value = `${nTitleNum++} 매출 현황`;
  worksheet1.getCell('Q10').value = '(단위 : 원, VAT별도) ';
  worksheet1.getCell('Q10').alignment = alignmentRight;
  worksheet1.getCell('B11').value = '구분';
  worksheet1.getCell('C11').value = '정기권 매출';
  worksheet1.getCell('G11').value = '일반권 매출';
  worksheet1.getCell('K11').value = '할인권 매출';
  worksheet1.getCell('O11').value = '기타 매출';
  worksheet1.getCell('S11').value = '합 계';
  worksheet1.getCell('B12').value = '현금';
  worksheet1.getCell('B13').value = '카드';
  worksheet1.getCell('B14').value = '매출합계 (a)';
  // worksheet1.getCell('C12').value = parseFloat(costData[0][1]['Sales-SeasonTicket']);
  // worksheet1.getCell('G12').value = parseFloat(costData[0][1]['Sales-GeneralSales']) ;
  // worksheet1.getCell('K12').value = parseFloat(costData[0][1]['Sales-EtcSales']) ;
  // worksheet1.getCell('S12').value = parseFloat(costData[0][1]['Sales-Total']) ;

  let SalesCnt = costData[1].length;
  worksheet1.getCell('C12').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$12,매출!$K$3:$K$${
      SalesCnt + 8
    },$C$11)`,
  };
  worksheet1.getCell('G12').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$12,매출!$K$3:$K$${
      SalesCnt + 8
    },$G$11)`,
  };
  worksheet1.getCell('K12').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$12,매출!$K$3:$K$${
      SalesCnt + 8
    },$K$11)`,
  };
  worksheet1.getCell('O12').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$12,매출!$K$3:$K$${
      SalesCnt + 8
    },$O$11)`,
  };
  worksheet1.getCell('S12').value = { formula: 'SUM(C12:R12)' };

  worksheet1.getCell('C12').numFmt = '#,##0';
  worksheet1.getCell('G12').numFmt = '#,##0';
  worksheet1.getCell('K12').numFmt = '#,##0';
  worksheet1.getCell('O12').numFmt = '#,##0';
  worksheet1.getCell('S12').numFmt = '#,##0';
  worksheet1.getCell('C12').alignment = alignmentRight;
  worksheet1.getCell('G12').alignment = alignmentRight;
  worksheet1.getCell('K12').alignment = alignmentRight;
  worksheet1.getCell('O12').alignment = alignmentRight;
  worksheet1.getCell('S12').alignment = alignmentRight;

  // worksheet1.getCell('C13').value = parseFloat(costData[0][0]['Sales-SeasonTicket']) ;
  // worksheet1.getCell('G13').value = parseFloat(costData[0][0]['Sales-GeneralSales']) ;
  // worksheet1.getCell('K13').value = parseFloat(costData[0][0]['Sales-EtcSales']) ;
  // worksheet1.getCell('S13').value = parseFloat(costData[0][0]['Sales-Total']) ;

  worksheet1.getCell('C13').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$13,매출!$K$3:$K$${
      SalesCnt + 8
    },$C$11)`,
  };
  worksheet1.getCell('G13').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$13,매출!$K$3:$K$${
      SalesCnt + 8
    },$G$11)`,
  };
  worksheet1.getCell('K13').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$13,매출!$K$3:$K$${
      SalesCnt + 8
    },$K$11)`,
  };
  worksheet1.getCell('O13').value = {
    formula: `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},매출!$J$3:$J$${SalesCnt + 8},$B$13,매출!$K$3:$K$${
      SalesCnt + 8
    },$O$11)`,
  };
  worksheet1.getCell('S13').value = { formula: 'SUM(C13:R13)' };

  worksheet1.getCell('C13').numFmt = '#,##0';
  worksheet1.getCell('G13').numFmt = '#,##0';
  worksheet1.getCell('K13').numFmt = '#,##0';
  worksheet1.getCell('O13').numFmt = '#,##0';
  worksheet1.getCell('S13').numFmt = '#,##0';
  worksheet1.getCell('C13').alignment = alignmentRight;
  worksheet1.getCell('G13').alignment = alignmentRight;
  worksheet1.getCell('K13').alignment = alignmentRight;
  worksheet1.getCell('O13').alignment = alignmentRight;
  worksheet1.getCell('S13').alignment = alignmentRight;

  worksheet1.getCell('B11').fill = fillGrey;
  worksheet1.getCell('C11').fill = fillGrey;
  worksheet1.getCell('G11').fill = fillGrey;
  worksheet1.getCell('L11').fill = fillGrey;
  worksheet1.getCell('O11').fill = fillGrey;
  worksheet1.getCell('S11').fill = fillGrey;
  worksheet1.getCell('B12').fill = fillGrey;
  worksheet1.getCell('B13').fill = fillGrey;
  worksheet1.getCell('B14').fill = fillGrey;

  // worksheet1.getCell('C14').value = parseFloat(costData[0][2]['Sales-SeasonTicket']) ;
  // worksheet1.getCell('G14').value = parseFloat(costData[0][2]['Sales-GeneralSales']) ;
  // worksheet1.getCell('K14').value = parseFloat(costData[0][2]['Sales-EtcSales']) ;
  // worksheet1.getCell('S14').value = parseFloat(costData[0][2]['Sales-Total']) ;

  worksheet1.getCell('C14').value = { formula: 'SUM(C12:F13)' };
  worksheet1.getCell('G14').value = { formula: 'SUM(G12:J13)' };
  worksheet1.getCell('K14').value = { formula: 'SUM(K12:N13)' };
  worksheet1.getCell('O14').value = { formula: 'SUM(O12:R13)' };
  worksheet1.getCell('S14').value = { formula: 'SUM(S12:V13)' };

  worksheet1.getCell('C14').numFmt = '#,##0';
  worksheet1.getCell('G14').numFmt = '#,##0';
  worksheet1.getCell('K14').numFmt = '#,##0';
  worksheet1.getCell('O14').numFmt = '#,##0';
  worksheet1.getCell('S14').numFmt = '#,##0';
  worksheet1.getCell('C14').alignment = alignmentRight;
  worksheet1.getCell('G14').alignment = alignmentRight;
  worksheet1.getCell('K14').alignment = alignmentRight;
  worksheet1.getCell('O14').alignment = alignmentRight;
  worksheet1.getCell('S14').alignment = alignmentRight;

  let nRow = 16;
  worksheet1.getCell(`B${nRow++}`).value = `${nTitleNum++}.수익배분 정산`;
  // 비용 내역
  worksheet1.getCell(`B${nRow++}`).value = `  ${nSectionNum++}) 비용 내역`;

  worksheet1.getCell(`B${nRow}`).value = '구분';
  worksheet1.getCell(`C${nRow}`).value = '공급가액';
  worksheet1.getCell(`H${nRow}`).value = '부가세';
  worksheet1.getCell(`M${nRow}`).value = '합계';
  worksheet1.getCell(`R${nRow}`).value = '비 고';
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`C${nRow}`).fill = fillGrey;
  worksheet1.getCell(`H${nRow}`).fill = fillGrey;
  worksheet1.getCell(`M${nRow}`).fill = fillGrey;
  worksheet1.getCell(`R${nRow}`).fill = fillGrey;
  nRow++;

  worksheet1.getCell(`C${nRow}`).value = { formula: `지출!E${rnumLastRow}` };
  worksheet1.getCell(`H${nRow}`).value = { formula: `+ROUND(C${nRow}*0.1,0)` };
  worksheet1.getCell(`M${nRow}`).value = { formula: `C${nRow}+H${nRow}` };
  // worksheet1.getCell('C19').value = parseFloat(costData[0][2]['Cost-Total']);
  // worksheet1.getCell('H19').value = parseFloat((Math.round(( costData[0][2]['Cost-Total'] * 10 / 110))));
  // worksheet1.getCell('M19').value = parseFloat(costData[0][2]['Cost-Total-WT']);
  worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`B${nRow}`).value = '운영비용(b)';
  nRow++;
  nRow++;

  // 2) 수익배분내역
  worksheet1.getCell(`B${nRow++}`).value = `  ${nSectionNum++}) 수익배분내역`;

  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`C${nRow}`).fill = fillGrey;
  worksheet1.getCell(`H${nRow}`).fill = fillGrey;
  worksheet1.getCell(`M${nRow}`).fill = fillGrey;
  worksheet1.getCell(`R${nRow}`).fill = fillGrey;

  worksheet1.getCell(`B${nRow}`).value = '구분';
  worksheet1.getCell(`C${nRow}`).value = '수익 (a)-(b)';
  worksheet1.getCell(`H${nRow}`).value = sProfitUser + '% (갑)';
  worksheet1.getCell(`M${nRow}`).value = sProfitOur + '% (하이파킹)';
  worksheet1.getCell(`R${nRow++}`).value = '비 고';
  worksheet1.getCell(`C${nRow}`).value =
    sSalesDeduction > 0 ? { formula: `S14-C19-${sSalesDeduction}` } : { formula: `S14-C19` };
  if (bIsDeficit) {
    worksheet1.getCell(`H${nRow}`).value = 0;
    worksheet1.getCell(`M${nRow}`).value = 0;
  } else {
    worksheet1.getCell(`H${nRow}`).value = {
      formula: `ROUND(+C${nRow}*${parseFloat(sProfitUser) / 100},0)`,
    };
    worksheet1.getCell(`M${nRow}`).value = { formula: `+C${nRow}-H${nRow}` };
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
  }

  worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;

  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`B${nRow}`).value = '수익배분';
  const nRow수익배분 = nRow;
  nRow++;
  nRow++;
  let nRow주차지원금 = 0;

  // 3) 주차 지원금 지급 내역 (있는 경우)
  if (sParkingSubsidyAmount) {
    worksheet1.getCell(`B${nRow++}`).value = `  ${nSectionNum++}) 지원금 지급 내역`;

    MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    worksheet1.getCell(`C${nRow}`).fill = fillGrey;
    worksheet1.getCell(`H${nRow}`).fill = fillGrey;
    worksheet1.getCell(`M${nRow}`).fill = fillGrey;
    worksheet1.getCell(`R${nRow}`).fill = fillGrey;

    worksheet1.getCell(`B${nRow}`).value = '구분';
    worksheet1.getCell(`C${nRow}`).value = '수익 (a)-(b)';
    worksheet1.getCell(`H${nRow}`).value = '(갑)';
    worksheet1.getCell(`M${nRow}`).value = '(하이파킹)';
    worksheet1.getCell(`R${nRow++}`).value = '비 고';

    nRow주차지원금 = nRow;
    worksheet1.getCell(`C${nRow}`).value = { formula: `H${nRow}+M${nRow}` };
    worksheet1.getCell(`M${nRow}`).value = sParkingSubsidyAmount;

    worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;

    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    worksheet1.getCell(`B${nRow}`).value = sParkingSubsidyAmountName;
    nRow++;
    nRow++;
  }
  // 3)4) 갑사 청구분
  worksheet1.getCell(`B${nRow++}`).value = sFixedRentalAmount
    ? `  ${nSectionNum}) 최종 청구 내역`
    : `  ${nSectionNum}) 갑사 청구분`;
  nSectionNum++;

  MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
  MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
  MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
  MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
  MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`C${nRow}`).fill = fillGrey;
  worksheet1.getCell(`H${nRow}`).fill = fillGrey;
  worksheet1.getCell(`M${nRow}`).fill = fillGrey;
  worksheet1.getCell(`R${nRow}`).fill = fillGrey;

  worksheet1.getCell(`B${nRow}`).value = '구분';
  worksheet1.getCell(`C${nRow}`).value = '공급가액';
  worksheet1.getCell(`H${nRow}`).value = '부가세';
  worksheet1.getCell(`M${nRow}`).value = '합계';
  worksheet1.getCell(`R${nRow}`).value = '비 고';

  if (sFixedRentalAmount) {
    nRow++;
    MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
    MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
    MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
    MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;

    worksheet1.getCell(`B${nRow}`).value = sFixedRentalAmountName;
    worksheet1.getCell(`C${nRow}`).value = sFixedRentalAmount;
    worksheet1.getCell(`H${nRow}`).value = sFixedRentalAmount * 0.1;
    worksheet1.getCell(`M${nRow}`).value = sFixedRentalAmount * 1.1;
    worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenter;
    worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`S${nRow}`).alignment = alignmentCenter;
  }
  nRow++;
  MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
  MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
  MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
  MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
  MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);

  const baseCell = bIsDeficit && sIsDeficitCoverage === '예' ? 'C' : 'H';
  const parkingSubsidy = sParkingSubsidyAmount ? `+M${nRow주차지원금}` : '';

  const tempFormula = `${baseCell}${nRow수익배분}${parkingSubsidy}`;

  worksheet1.getCell(`C${nRow}`).value = { formula: tempFormula };
  worksheet1.getCell(`H${nRow}`).value = { formula: `+ROUND(C${nRow}*0.1,0)` };
  if (sIsTaxFree === '예') {
    worksheet1.getCell(`H${nRow}`).value = { formula: `+ROUND(C${nRow}*0.1,0)*0` };
  }
  worksheet1.getCell(`M${nRow}`).value = { formula: `C${nRow}+H${nRow}` };

  worksheet1.getCell(`B${nRow}`).value = '수 익 배 분';
  worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;

  if (sFixedRentalAmount) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
    MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
    MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
    MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = '최종 수익금';
    worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`S${nRow}`).value = ' 갑사 계산서 청구';
    worksheet1.getCell(`C${nRow}`).value = { formula: `C${nRow - 2}+C${nRow - 1}` };
    worksheet1.getCell(`H${nRow}`).value = { formula: `H${nRow - 2}+H${nRow - 1}` };
    worksheet1.getCell(`M${nRow}`).value = { formula: `M${nRow - 2}+M${nRow - 1}` };

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenter;
    worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`S${nRow}`).alignment = alignmentCenter;
  }

  if (sCharge001) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
    MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
    MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
    MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = sCharge001Name;
    worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
    worksheet1.getRow(nRow).height = 35;
    worksheet1.getCell(`S${nRow}`).fill = fillYellow;

    worksheet1.getCell(`C${nRow}`).value = sCharge001;
    worksheet1.getCell(`H${nRow}`).value = sCharge001 * 0.1;
    worksheet1.getCell(`M${nRow}`).value = sCharge001 * 1.1;
    worksheet1.getCell(`S${nRow}`).value = '하이파킹\n->갑사(계산서발급)';

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenter;
    worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`S${nRow}`).alignment = wrapTextAlignment;
  }
  if (sCharge002) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
    MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
    MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
    MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = sCharge002Name;
    worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`S${nRow}`).value = '하이파킹\n->갑사(계산서발급)';
    worksheet1.getRow(nRow).height = 35;
    worksheet1.getCell(`S${nRow}`).fill = fillYellow;
    worksheet1.getCell(`C${nRow}`).value = sCharge002;
    worksheet1.getCell(`H${nRow}`).value = sCharge002 * 0.1;
    worksheet1.getCell(`M${nRow}`).value = sCharge002 * 1.1;

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenter;
    worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`S${nRow}`).alignment = wrapTextAlignment;
  }
  if (sCharge003) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:B${nRow}`);
    MergeCellsWithStyle(worksheet1, `C${nRow}:G${nRow}`);
    MergeCellsWithStyle(worksheet1, `H${nRow}:L${nRow}`);
    MergeCellsWithStyle(worksheet1, `M${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:V${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = sCharge003Name;
    worksheet1.getCell(`C${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`H${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`M${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`S${nRow}`).value = '하이파킹\n->갑사(계산서발급)';
    worksheet1.getRow(nRow).height = 35;
    worksheet1.getCell(`S${nRow}`).fill = fillYellow;
    worksheet1.getCell(`C${nRow}`).value = sCharge003;
    worksheet1.getCell(`H${nRow}`).value = sCharge003 * 0.1;
    worksheet1.getCell(`M${nRow}`).value = sCharge003 * 1.1;

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenter;
    worksheet1.getCell(`C${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`H${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`M${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`S${nRow}`).alignment = wrapTextAlignment;
  }
  worksheet1.pageSetup.scale = 83;
  worksheet1.views = [{ state: 'normal', showGridLines: false }];
}
