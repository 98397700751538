import React, { useRef, useState, useCallback, useEffect } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { cancelSalesManualLink } from 'constants/hyperlink';
import { parking, aggregation, error, salesPayment } from 'api';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { Tooltip } from 'devextreme-react/tooltip';

import ScrollView from 'devextreme-react/scroll-view';
import DateBox from 'devextreme-react/date-box';
import GeneralTicket from './generalTicket';
import SeasonTicket from './seasonTicket';
import EtcSales from './etcSales';
import Tax from './tax';
import SuspenseReceipt from './suspenseReceipt';
import moment from 'moment';
import notify from 'devextreme/ui/notify';
import CancelSalesForm from './component/cancel-sales-form';
import CancelConfirmReqTicket from './component/cancel-confirm-req-ticket';
import CancelEditForm from '../cancel-sales-confirm/component/cancel-edit-form';
import './cancel-sales.scss';

const CancelSalesPage = () => {
  const generalTicketRef = useRef({});
  const seasonTicketRef = useRef({});
  const etcSalesRef = useRef({});
  const taxinvoiceRef = useRef({});
  const cancelTicketReqRef = useRef({});
  const susprcptRef = useRef({});
  // taxInvoice ref
  const tGeneralTicketRef = useRef({});
  const tSeasonTicketRef = useRef({});
  const tEtcRef = useRef({});
  const tB2BRef = useRef({});

  const [gRowdata, setGRowdata] = useState([]);
  const [sRowdata, setSRowdata] = useState([]);
  const [eRowdata, setERowdata] = useState([]);
  const [cRowdata, setCRowdata] = useState([]);
  const [tRowdata, setTRowdata] = useState([]);
  const [srRowdata, setSRRowdata] = useState([]);

  const [tSalesRowdata, setTSalesRowdata] = useState([]);
  const [refundReason, setRefundReason] = useState([]);
  const [refundPaymentType, setRefundPaymentType] = useState([]);

  const [searchCenter, setSearchCenter] = useState('');
  const [searchCardNo, setSearchCardNo] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');
  const [searchVehicleNumber, setSearchVehicleNumber] = useState('');

  const [gDataSource, setGDataSource] = useState([]);
  const [sDataSource, setSDataSource] = useState([]);
  const [eDataSource, setEDataSource] = useState([]);
  const [tDataSource, setTDataSource] = useState([]);
  const [srDataSource, setSRDataSource] = useState([]);

  const [tabIdx, setTabIdx] = useState(0);

  const [popup, setPopup] = useState({ save: false, visible: false });

  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(5, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });

  // 요청 목록 수정팝업
  const [editPopup, setEditPopup] = useState({ save: false, visible: false });
  const [editRowdata, setEditRowdata] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const [isRefresh, setIsRefresh] = useState(false);
  const tooltipRef = useRef({});
  useEffect(() => {
    api();
  }, []);

  const api = useCallback(async () => {
    const RefundPaymentType = [];
    const RefundReason = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['RefundPaymentType', 'RefundReason'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'RefundPaymentType') {
          RefundPaymentType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'RefundReason') {
          RefundReason.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
      setRefundPaymentType(RefundPaymentType);
      setRefundReason(RefundReason);
    }
  }, []);

  const refresh = () => {
    setIsRefresh(!isRefresh);
    callAPI();
    // refreshTabs();
  };
  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  const onChangeCardNo = e => {
    setSearchCardNo(e.value);
  };
  const onChangeApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };
  const onChangeVehicleNumber = e => {
    setSearchVehicleNumber(e.value);
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  //요청목록 리프레쉬
  const refreshCancelConfirmTicket = () => {
    if (cancelTicketReqRef.current.instance) cancelTicketReqRef.current.instance.refresh();
  };

  const onCancel = () => {
    const hasGRowdata = gRowdata.length > 0;
    const hasSRowdata = sRowdata.length > 0;
    const hasERowdata = eRowdata.length > 0;
    const hasTRowdata = tRowdata.length > 0;
    const hasSRRowdata = srRowdata.length > 0;

    if (!hasGRowdata && !hasSRowdata && !hasERowdata && !hasTRowdata && !hasSRRowdata) {
      return alert('취소할 매출을 선택해주세요');
    }
    if (hasTRowdata) {
      const getSelectedRowKeys = ref => {
        const instance = ref.current?.instance;
        return instance ? instance.getSelectedRowKeys() : [];
      };
      const tGeneralTicketData = getSelectedRowKeys(tGeneralTicketRef);
      const tSeasonTicketData = getSelectedRowKeys(tSeasonTicketRef);
      const tEtcData = getSelectedRowKeys(tEtcRef);
      const tB2BData = getSelectedRowKeys(tB2BRef);

      const selectedRowData = [...tGeneralTicketData, ...tSeasonTicketData, ...tEtcData, ...tB2BData];
      const hasTDetaildata = selectedRowData.length > 0;
      const hasPeriodSales = selectedRowData.some(item => {
        return item.SalesType === 'SeasonTicket' || item.EtcSalesType === '007';
      });
      const isMismatchError = selectedRowData.some((item, index, array) => {
        return array
          .slice(index + 1)
          .some(
            innerItem =>
              innerItem.SalesType !== item.SalesType ||
              innerItem.FromDate !== item.FromDate ||
              innerItem.ToDate !== item.ToDate,
          );
      });
      if (!hasTDetaildata) {
        return alert('취소할 매출을 선택해주세요');
      }
      if (hasPeriodSales && isMismatchError) {
        return alert(
          '선택한 매출들의 매출 기간이 동일하지 않습니다. 동일한 기간(혹은 기간없음)의 매출로만 환불 대상을 선택해 주세요.',
        );
      }
      const selectedRowDataSalesPrice = selectedRowData.reduce((a, c) => {
        return a + c.SalesPrice;
      }, 0);

      const isPartial = tRowdata[0].SalesPrice !== selectedRowDataSalesPrice;
      setTSalesRowdata({ isPartial, data: selectedRowData });
    }
    setPopup({ save: false, visible: true });
  };
  const onClose = () => {
    setPopup({ ...popup, visible: false });
    setEditRowdata({ ...editPopup, visible: false });
    clearSelection();
  };
  const onSelectedIndexChange = e => {
    setTabIdx(e);
    clearSelection();
    handleTootipShow();
  };
  const clearSelection = () => {
    const refs = [
      generalTicketRef,
      seasonTicketRef,
      etcSalesRef,
      taxinvoiceRef,
      susprcptRef,
      tGeneralTicketRef,
      tSeasonTicketRef,
      tEtcRef,
      tB2BRef,
    ];
    refs.forEach(ref => {
      ref.current?.instance?.clearSelection();
    });
  };

  const refreshTabs = () => {
    const refs = [generalTicketRef, seasonTicketRef, etcSalesRef, taxinvoiceRef, susprcptRef];
    refs.forEach(ref => {
      ref.current?.instance?.refresh();
    });
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onSearch = () => {
    callAPI();
  };

  const onEnterKey = () => {
    callAPI();
  };

  const callAPI = refreshIdx => {
    const isRequiredValidation = tabIdx === 0 || tabIdx === 3;
    const isSearchDataValid = [searchCenter, searchCardNo, searchApprovalNo, searchVehicleNumber].some(Boolean);
    if (isRequiredValidation && !isSearchDataValid) {
      alert('검색 조건을 하나 이상 입력하세요.');
      return;
    }
    const idx = refreshIdx ? refreshIdx : tabIdx;
    const searchParam = {
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      SearchCenter: searchCenter.trim(),
      SearchCardNo: searchCardNo.trim(),
      SearchApprovalNo: searchApprovalNo.trim(),
      SearchVehicleNumber: searchVehicleNumber.trim(),
      RegisterUser: '',
      RequestUser: '',
      IsForCancel: 'Y',
    };
    clearSelection();
    if (idx === 0) {
      getGDataSource(searchParam);
    } else if (idx === 1) {
      getSDataSource(searchParam);
    } else if (idx === 2) {
      getEDataSource(searchParam);
    } else if (idx === 3) {
      getTDataSource(searchParam);
    } else if (idx === 4) {
      getSRDataSource(searchParam);
    }
  };

  const getGDataSource = searchParam => {
    (async () => {
      setLoadPanelVisible(true);
      const result = await salesPayment.getParkingSalesDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setGDataSource(result.data);
        setLoadPanelVisible(false);
      } else {
        setLoadPanelVisible(false);
        setGDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const getSDataSource = searchParam => {
    (async () => {
      setLoadPanelVisible(true);
      const result = await salesPayment.getSeasonTicketSalesDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setSDataSource(result.data);
        setLoadPanelVisible(false);
      } else {
        setLoadPanelVisible(false);
        setSDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const getEDataSource = searchParam => {
    (async () => {
      setLoadPanelVisible(true);
      const result = await salesPayment.getEtcSalesDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setEDataSource(result.data);
        setLoadPanelVisible(false);
      } else {
        setLoadPanelVisible(false);
        setEDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const getTDataSource = searchParam => {
    (async () => {
      setLoadPanelVisible(true);
      const result = await salesPayment.getTaxDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setTDataSource(result.data);
        setLoadPanelVisible(false);
      } else {
        setLoadPanelVisible(false);
        setTDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const getSRDataSource = searchParam => {
    (async () => {
      setLoadPanelVisible(true);
      const result = await aggregation.getSuspenseReceiptsDetailConfirmed(searchParam);
      if (result.isOk) {
        setSRDataSource(result.data);
        setLoadPanelVisible(false);
      } else {
        setLoadPanelVisible(false);
        setSRDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const onUndoCancel = () => {
    if (cRowdata.length === 0) {
      alert('매출을 선택해주세요.');
    } else {
      const result = confirm('<i>회수 하시겠습니까?</i>', '확인');
      result.then(dialogResult => {
        if (dialogResult) {
          const Idx = [];
          cRowdata.forEach(r => {
            Idx.push(r.Idx);
          });
          setLoadPanelVisible(true);
          aggregation
            .cancelRefundInfo({
              Idx,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '회수 되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                refresh();
                cancelTicketReqRef.current.instance.refresh();
                cancelTicketReqRef.current.instance.clearSelection();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const handleTootipShow = () => {
    tooltipRef.current.instance.show();
    setTimeout(() => {
      tooltipRef.current.instance.hide();
    }, 2000);
  };

  return (
    <>
      <React.Fragment>
        <div className="content-block detail-title left-right-header">
          <h2> 매출 취소 요청</h2>
          <div>
            <a
              style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
              href={cancelSalesManualLink}
              target="blank"
            >
              메뉴얼 바로가기
            </a>
            <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
          </div>
        </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <h3 style={{ margin: '1px 5px' }}>취소 요청 등록</h3>
            <Toolbar>
              <ToolbarItem location={'before'}>
                <div className="label">매출일/확정일 :</div>
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  value={dateValue.fromDate}
                />
              </ToolbarItem>
              <ToolbarItem location={'before'} text="~" />
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="ToDate"
                  min={dateValue.fromDate}
                  value={dateValue.toDate}
                  onValueChanged={handleToDate}
                  dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchCenter"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="사업소명/P-코드/사업소코드"
                  onValueChanged={onChangeCenter}
                  onEnterKey={onEnterKey}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchApprovalNo"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="승인번호"
                  onValueChanged={onChangeApprovalNo}
                  onEnterKey={onEnterKey}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchCardNo"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="카드번호"
                  onValueChanged={onChangeCardNo}
                  onEnterKey={onEnterKey}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchVehicleNumber"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="차량번호"
                  onValueChanged={onChangeVehicleNumber}
                  onEnterKey={onEnterKey}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button icon="search" text="조회" width={120} onClick={onSearch} />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button text="취소 요청" width={120} onClick={onCancel} />
              </ToolbarItem>
            </Toolbar>
            <div style={{ paddingTop: '5px' }}>
              <Tooltip
                ref={tooltipRef}
                target="#tabs"
                showEvent="manual"
                hideEvent="manual"
                position={{
                  at: 'top',
                  my: 'top',
                  // of: '#tabs',
                }}
              >
                <div>탭을 변경 하신 후 [조회]를 다시 클릭해 주세요</div>
              </Tooltip>
              <TabPanel onSelectedIndexChange={onSelectedIndexChange} id="tabs">
                <Item title="일반권">
                  <GeneralTicket
                    generalTicketRef={generalTicketRef}
                    setGRowdata={setGRowdata}
                    dataSource={gDataSource}
                    setLoadPanelVisible={setLoadPanelVisible}
                  />
                </Item>
                <Item title="정기권">
                  <SeasonTicket seasonTicketRef={seasonTicketRef} setSRowdata={setSRowdata} dataSource={sDataSource} />
                </Item>
                <Item title="기타">
                  <EtcSales etcSalesRef={etcSalesRef} setERowdata={setERowdata} dataSource={eDataSource} />
                </Item>
                <Item title="세금계산서">
                  <Tax
                    tGeneralTicketRef={tGeneralTicketRef}
                    tSeasonTicketRef={tSeasonTicketRef}
                    tEtcRef={tEtcRef}
                    tB2BRef={tB2BRef}
                    taxinvoiceRef={taxinvoiceRef}
                    dataSource={tDataSource}
                    setTRowdata={setTRowdata}
                  />
                </Item>
                <Item title="가수금">
                  <SuspenseReceipt susprcptRef={susprcptRef} setSRRowdata={setSRRowdata} dataSource={srDataSource} />
                </Item>
              </TabPanel>
            </div>
            <div style={{ paddingTop: '15px' }}>
              <CancelConfirmReqTicket
                headerBefore={[
                  <div style={{ display: 'flex', alignItems: 'center', gap: '3px', paddingBottom: '15px' }}>
                    <h3 style={{ margin: '0px 5px' }}>취소 요청 목록</h3>
                  </div>,
                ]}
                headerAfter={[
                  <Button icon="undo" text="요청 회수" width={120} onClick={onUndoCancel} />,
                  <Button icon="refresh" onClick={() => cancelTicketReqRef.current.instance.refresh()} />,
                ]}
                cancelTicketReqRef={cancelTicketReqRef}
                setPopup={setEditPopup}
                setCRowdata={setCRowdata}
                setEditRowdata={setEditRowdata}
                height={430}
                isHistory
                isRefresh={isRefresh}
                isNotSearch
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </div>
        </div>
        <Popup
          onHiding={onClose}
          visible={popup.visible}
          showCloseButton={true}
          showTitle={false}
          width="75%"
          height={700}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <CancelSalesForm
                setPopup={setPopup}
                gRowdata={gRowdata}
                sRowdata={sRowdata}
                eRowdata={eRowdata}
                tRowdata={tRowdata}
                srRowdata={srRowdata}
                refundReason={refundReason}
                refundPaymentType={refundPaymentType}
                refresh={refresh}
                setLoadPanelVisible={setLoadPanelVisible}
                tSalesRowdata={tSalesRowdata}
                popup={popup}
              />
            </div>
          </ScrollView>
        </Popup>
        <Popup
          onHiding={onClose}
          visible={editPopup.visible}
          showCloseButton={true}
          showTitle={false}
          width="75%"
          height={730}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <CancelEditForm
                setPopup={setEditPopup}
                editRowdata={editRowdata}
                refundReason={refundReason}
                refundPaymentType={refundPaymentType}
                refresh={refreshCancelConfirmTicket}
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </ScrollView>
        </Popup>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </React.Fragment>
    </>
  );
};

export default CancelSalesPage;
