import { exportDataGrid } from 'devextreme/excel_exporter';
import { autoFitColumnWidth } from 'utils/common';

export async function createWorksheet2(worksheet2, gridsalesRef, costData) {
  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };

  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };

  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };

  const nSalesLegth = costData[1].length;
  // 매출 시트
  const exporGridResult = exportDataGrid({
    component: gridsalesRef.current.instance,
    worksheet: worksheet2,
    topLeftCell: { row: 2, column: 1 },
    customizeCell: options => {
      if (options.gridCell.column.dataType === 'number') {
        options.excelCell.style = { numFmt: '#,##0' };
      }
      if (options.gridCell.rowType === 'header') {
        options.excelCell.fill = fillGrey;
      }
      options.excelCell.font = { name: '맑은 고딕', size: 10 };
      options.excelCell.alignment = { wrapText: true };
      options.excelCell.border = borderAll;
    },
    autoFilterEnabled: true,
  });
  await exporGridResult;

  worksheet2.getColumn('A').alignment = alignmentCenter;
  worksheet2.getColumn('B').alignment = alignmentCenter;
  worksheet2.getColumn('D').alignment = alignmentCenter;
  worksheet2.getColumn('E').alignment = alignmentCenter;
  worksheet2.getColumn('J').alignment = alignmentCenter;
  worksheet2.getColumn('K').alignment = alignmentCenter;
  worksheet2.getCell('A1').alignment = alignmentLeft;
  worksheet2.getRow(2).alignment = alignmentCenter;
  worksheet2.getCell('A1').value = {
    richText: [{ text: `1. 매출 현황`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
  };

  worksheet2.getCell(`A${nSalesLegth + 3}`).value = '합계';
  worksheet2.getCell(`F${nSalesLegth + 3}`).value = { formula: `SUM(F3:F${nSalesLegth + 2})` };
  worksheet2.getCell(`G${nSalesLegth + 3}`).value = { formula: `SUM(G3:G${nSalesLegth + 2})` };
  worksheet2.getCell(`H${nSalesLegth + 3}`).value = { formula: `SUM(H3:H${nSalesLegth + 2})` };
  worksheet2.getCell(`I${nSalesLegth + 3}`).value = { formula: `SUM(I3:I${nSalesLegth + 2})` };

  worksheet2.getCell(`A${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`B${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`C${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`D${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`E${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`F${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`G${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`H${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`I${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`J${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.getCell(`K${nSalesLegth + 3}`).fill = fillGrey;
  worksheet2.pageSetup.scale = 54;

  autoFitColumnWidth(worksheet2);
  worksheet2.getColumn(1).width = 8;
}
