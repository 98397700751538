import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';

import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import ScrollView from 'devextreme-react/scroll-view';

import { profitDistributionCodeMasterManualLink } from 'constants/hyperlink';
import { master } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import CodeMasterForm from '../code-master/components/code-master-form';
import './cost-code-master.scss';

const userId = localStorage.getItem('userId');

const CostCodeMasterPage = () => {
  const [popupVisible, setPopupVisibility] = useState({
    save: false,
    close: false,
  });
  const [selectItem, setSelectItem] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchParams, setSearchParams] = useState({
    CodeGroup: '',
    SearchText: '',
  });
  const [text, setText] = useState('');
  const [itemValue, setItemValue] = useState(null);

  const gridRef = useRef({});

  useEffect(() => {
    getCodeType();
  }, []);

  const getCodeType = useCallback(async () => {
    const result = await master.searchCodeMaster({
      CodeGroup: '',
      SearchText: 'ParkingCostGroupMaster',
      OnlyUsing: true,
    });
    if (result.isOk && result.data.length > 0) {
      setSelectItem(result.data);
      setItemValue(result.data[0].Code);
      setSearchParams({
        CodeGroup: result.data[0].Code,
        SearchText: '',
        CodeGroupName: result.data[0].CodeDesc,
      });
    }
  }, []);

  useEffect(() => {
    if (itemValue && popupVisible.save) {
      setSearchParams({
        ...searchParams,
        CodeGroup: itemValue,
        SearchText: text,
      });
    }
  }, [popupVisible]);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'CodeGroup',
      loadMode: 'raw',
      load: async () => {
        try {
          const { CodeGroup, SearchText } = searchParams;
          const result = await axios.post(`master/codemaster/searchCodeMasterALL`, {
            CodeGroup,
            SearchText: '',
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams]);

  const togglePopup = (type, data) => {
    setFormData(data);
    setIsUpdate(!!(type === 'update'));
    setPopupVisibility({ save: false, close: !popupVisible.close });
  };

  const editCellRender = e => {
    return (
      <Button
        icon="edit"
        width={120}
        text="수정"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup('update', e.data)}
      />
    );
  };

  const isUsedCellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'N' ? '아니오' : '예';
  };

  const onClickButton = e => {
    const { Code, CodeDesc } = e.itemData;
    setSearchParams({
      ...searchParams,
      CodeGroup: Code,
      CodeGroupName: CodeDesc,
    });
    setItemValue(Code);
  };

  const onChangeSearchText = e => {
    setText(e.value);
  };

  const onEnterKey = e => {
    setSearchParams({
      ...searchParams,
      SearchText: text.trim(),
    });
  };

  const onSearch = e => {
    setSearchParams({
      ...searchParams,
      SearchText: text.trim(),
    });
  };

  const onClose = () => {
    setPopupVisibility({ ...popupVisible, close: false });
  };

  return (
    <>
      <div>
        <div className={'content-block detail-title left-right-header'}>
          <div>
            <h2>수익배분 - 코드 관리</h2>
          </div>
          <div>
            <a
              className="manual-link"
              style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
              href={profitDistributionCodeMasterManualLink}
              target="blank"
            >
              메뉴얼 바로가기
            </a>
            <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
          </div>
        </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              height={650}
              isHeader={true}
              isExcel={true}
              isInfiniteScroll={false}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '수익배분 마스터 코드 관리.xlsx',
              }}
              headerBefore={[
                <SelectBox
                  labelMode="static"
                  placeholder="select"
                  dropDownButtonTemplate="dropDownButton"
                  stylingMode="outlined"
                  dataSource={selectItem}
                  value={itemValue}
                  onItemClick={onClickButton}
                  valueExpr="Code"
                  displayExpr="CodeDesc"
                  width={250}
                />,
                <TextBox
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="코드나 코드명을 입력하세요"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={250}
                  mode="search"
                />,
              ]}
              headerAfter={[
                <Button
                  icon="search"
                  text="조회"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={onSearch}
                />,
                <Button
                  icon="plus"
                  text="등록"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={() => togglePopup('add')}
                />,
              ]}
            >
              <Paging defaultPageSize={15} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
              <Column dataField={'CodeGroupName'} caption={'코드 종류'} />
              {/* <Column dataField={'CodeGroup'} caption={'그룹코드'} /> */}
              <Column dataField={'CodeDesc'} caption={'코드명'} />
              <Column dataField={'Code'} caption={'코드'} />
              <Column dataField={'Attribute1'} caption={'속성값1'} />
              <Column dataField={'Remark'} caption={'비고'} />
              <Column dataField={'SortOrder'} alignment="left" caption={'정렬순서'} />
              <Column dataField={'UseYN'} caption={'사용여부'} cellRender={isUsedCellRender} />
              <Column
                dataField={'edit'}
                caption={'수정'}
                // hidingPriority={0}
                cellRender={editCellRender}
                allowExporting={false}
              />
            </CommonDataGrid>
          </div>
        </div>
        <Popup
          onHiding={onClose}
          visible={popupVisible.close}
          showTitle={false}
          width="40%"
          height={isUpdate ? 780 : 740}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <CodeMasterForm
                codeMng={formData}
                isUpdate={isUpdate}
                setPopupVisibility={setPopupVisibility}
                searchParams={searchParams}
                popupVisible={popupVisible}
              />
            </div>
          </ScrollView>
        </Popup>
      </div>
    </>
  );
};

export default CostCodeMasterPage;
