import React, { useCallback, useEffect, useRef, useState } from 'react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import { parking } from 'api';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, EmptyItem } from 'devextreme-react/form';
import { profitDistributionSettingManualLink } from 'constants/hyperlink';

import ParkingCostViewByMonth from './view-month-cost';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { Tooltip } from 'devextreme-react/tooltip';
import { notifyS } from 'common/popup/sales-common-util';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import { COMPANYCODE } from 'constants/codes';
import { base64forConstantF } from './base64/constantF';
import { base64forConstantG } from './base64/constantG';
import { confirm } from 'devextreme/ui/dialog';
import CostGrid from './cost-grid';
import './parking-cost-mgmt.scss';

const FIXED = 'Fixed';
const FLOATING = 'Floating';
const RECOGNITIONTYPE = 'RecognitionType';
const DISBURSEMENT = 'Disbursement';
const CHARGE = 'Charge';

// const DEFAULT_SELECTED_COST_CENTER_CODE = 'BtoB';

const ParkingCostMgmt = () => {
  const fixedRef = useRef({});
  const floatingRef = useRef({});
  const disbursementRef = useRef({});
  const chargeRef = useRef({});

  const [addCount, setAddCount] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [dsFixed, setDsFixed] = useState([]);
  const [dsFloating, setDsFloating] = useState([]);
  const [dsOthers, setDsOthers] = useState([]);
  const [dsDisbursement, setDsDisbursement] = useState([]);
  const [dsCharge, setDsCharge] = useState([]);
  const [dsRecogType, setDsRecogType] = useState([]);
  const [formData, setFormData] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const [monthlyDataSource, setMonthlyDataSource] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY/MM/DD'),
    CostCenterCode: '',
  });

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const handleSalesMonth = e => {
    setSearchData({
      ...searchData,
      SalesMonth: e.value,
    });
    getCostData(e.value, searchData.CostCenterCode);
  };

  useEffect(() => {
    (async () => {
      const result = await parking.searchCostCenterCost({
        CompanyCode: COMPANYCODE,
        SalesMonth: searchData.SalesMonth,
      });
      if (result.isOk) {
        const newDataSource = result.data[0];
        setDataSource(newDataSource);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, [searchData.SalesMonth]);

  const onSelectionChangedCenter = e => {
    if (e.selectedItem) {
      const { CostCenterCode } = e.selectedItem;
      setSearchData({
        ...searchData,
        CostCenterCode: CostCenterCode,
      });
      getCostData(searchData.SalesMonth, CostCenterCode);
    }
  };

  const getCostData = useCallback(async (salesMonth, costCenterCode) => {
    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: costCenterCode,
      SalesMonth: moment(salesMonth, 'YYYY/MM/DD').format('YYYYMM'),
    };

    const result = await parking.getParkingCostManagementData(params);
    if (!result.isOk) {
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    const monthlyResult = await parking.getParkingCostManagementDataAllMonth({ CostCenterCode: costCenterCode });
    if (!monthlyResult.isOk) {
      notifyS(JSON.parse(monthlyResult.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    const tmpData1 = monthlyResult.data && monthlyResult.data[0] ? monthlyResult.data[0] : [];
    setMonthlyDataSource(tmpData1);
    if (result.data[1].length > 0) {
      const {
        DistributionRateCond1,
        DistributionRateCond2,
        IsTaxFree,
        IsDeficitCoverage,
        Cond1Revenue,
        Cond2Revenue,
        MinimumRevenue,
        SalesDeduction,
      } = result.data[1][0];
      hasValue(DistributionRateCond2)
        ? setAddCount(3)
        : hasValue(DistributionRateCond1)
        ? setAddCount(2)
        : setAddCount(1);
      setFormData({
        ...result.data[1][0],
        IsTaxFree: IsTaxFree === '1',
        IsDeficitCoverage: IsDeficitCoverage === '1',
        MinimumRevenue: parseInt(MinimumRevenue),
        Cond1Revenue: parseInt(Cond1Revenue),
        Cond2Revenue: parseInt(Cond2Revenue),
        SalesDeduction: parseInt(SalesDeduction),
      });
    } else {
      setFormData({});
    }
    const tmpData = result.data && result.data[0] && result.data[1] ? result.data[0] : [];
    const tmpFixed = [];
    const tmpFloating = [];
    const tmpOthers = [];
    const tmpDisbursement = [];
    const tmpCharge = [];
    const tmpRecogType = [];

    tmpData.forEach((row, index) => {
      const tmpTarget =
        row.CostGroupCode === FIXED
          ? tmpFixed
          : row.CostGroupCode.startsWith(FLOATING)
          ? tmpFloating
          : row.CostGroupCode === DISBURSEMENT
          ? tmpDisbursement
          : row.CostGroupCode === CHARGE
          ? tmpCharge
          : row.CostGroupCode === RECOGNITIONTYPE
          ? tmpRecogType
          : tmpOthers;
      tmpTarget.push(row);
    });

    setDsFixed(tmpFixed);
    setDsFloating(tmpFloating);
    setDsDisbursement(tmpDisbursement);
    setDsCharge(tmpCharge);
    setDsRecogType(tmpRecogType);
    setLoadPanelVisible(false);
  }, []);

  const hasValue = value => {
    return value !== '0' && value !== null && value !== undefined && value !== 0 && value !== '';
  };
  const saveExpense = useCallback(async () => {
    const {
      RecipientName,
      RecipientEmail,
      CcEmail,
      BccEmail,
      IsTaxFree,
      IsDeficitCoverage,
      CostCenterName,
      SalesDeduction,
      DistributionRate,
      MinimumRevenue,
      DistributionRateCond1,
      DistributionRateCond2,
      Cond1Revenue,
      Cond2Revenue,
    } = formData;
    const tmpFixed = fixedRef.current.getDataSource();
    const tmpFloating = floatingRef.current.getDataSource();
    const tmpDisbursement = disbursementRef.current.getDataSource();
    const tmpCharge = chargeRef.current.getDataSource();
    const tmpIsTaxFree = IsTaxFree ? '1' : '0';
    const tmpIsDeficitCoverage = IsDeficitCoverage ? '1' : '0';
    const params = {
      CostCenterCode: searchData.CostCenterCode,
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      FixedItemListJson: JSON.stringify(tmpFixed),
      FloatingItemListJson: JSON.stringify(tmpFloating),
      DisbursementItemListJson: JSON.stringify(tmpDisbursement),
      ChargeItemListJson: JSON.stringify(tmpCharge),
      RecipientName,
      RecipientEmail,
      CcEmail,
      BccEmail,
      IsTaxFree: tmpIsTaxFree,
      IsDeficitCoverage: tmpIsDeficitCoverage,
      CostCenterName,
      SalesDeduction,
      DistributionRate,
      MinimumRevenue,
      DistributionRateCond1,
      DistributionRateCond2,
      Cond1Revenue,
      Cond2Revenue,
    };
    const IsSalesDeduction = SalesDeduction > 0;
    const IsNoFixedItemsUse = tmpFixed.every(a => !a.UseYN);
    if (IsSalesDeduction && !IsNoFixedItemsUse) {
      const result = await confirm(
        `<b>기본 정보 > 매출차감금액</b>을 입력하는 경우 <br />고정경비(B)는 <u>자동 체크 해제</u>됩니다. <br /><br /><i>계속 하시겠습니까?</i>`,
        '확인',
      );
      if (!result) {
        return;
      }
    }
    setLoadPanelVisible(true);
    const result = await parking.saveParkingCostManagementData(params);
    if (!result.isOk) {
      setLoadPanelVisible(false);
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      return;
    }

    notifyS('저장되었습니다.', 'success');
    setLoadPanelVisible(false);
    refresh();
  }, [searchData, formData]);

  const refresh = () => {
    getCostData(searchData.SalesMonth, searchData.CostCenterCode);
  };
  const percentFormat = value => {
    return `${value}%`;
  };

  const onAddClick = e => {
    if (addCount < 3) {
      setAddCount(prev => prev + 1);
    } else {
      alert('최대입니다. ');
    }
  };

  const onRemoveClick = e => {
    if (addCount === 3) {
      setAddCount(prev => prev - 1);
      setFormData({ ...formData, DistributionRateCond2: '', Cond2Revenue: '' });
    } else if (addCount === 2) {
      setAddCount(prev => prev - 1);
      setFormData({ ...formData, DistributionRateCond1: '', Cond1Revenue: '' });
    } else {
      alert('최소입니다. ');
    }
  };

  const toKrw = useCallback(str => {
    if (str) {
      const result =
        str.toLocaleString('ko-KR', {
          style: 'decimal',
          minimumFractionDigits: 0,
        }) + ' 원';
      return result;
    } else {
      return '0 원';
    }
  }, []);

  const RangeViewer = () => {
    const {
      DistributionRate,
      MinimumRevenue,
      DistributionRateCond1,
      Cond1Revenue,
      DistributionRateCond2,
      Cond2Revenue,
    } = formData;
    let message1 = '금액과 상관없이 배분율이 적용됩니다. ';
    let message2 = ' ';

    const minimumRevenue = toKrw(MinimumRevenue);
    const cond1Revenue = toKrw(Cond1Revenue);
    const cond2Revenue = toKrw(Cond2Revenue);
    // 첫 번째 조건 추가
    if (addCount === 1 && MinimumRevenue) {
      message1 = `매출이 ${minimumRevenue}을 초과하는 경우에만 배분율이 적용됩니다.`;
    }

    // 두 번째 조건 추가
    else if (addCount === 2) {
      message1 = ' ';
      message2 = `${DistributionRate || 0}% : ${minimumRevenue || 0} < 매출 ≤ ${cond1Revenue} 
${DistributionRateCond1 || 0}% : ${cond1Revenue} < 매출`;
    }

    // 세 번째 조건 추가
    else if (addCount === 3) {
      message1 = ' ';
      message2 = `${DistributionRate || 0}% :  ${minimumRevenue || 0} < 매출 ≤ ${cond1Revenue} 
${DistributionRateCond1 || 0}% : ${cond1Revenue} < 매출 ≤ ${cond2Revenue}
${DistributionRateCond2 || 0}% : ${cond2Revenue} < 매출`;
    }
    return (
      <div>
        <div style={{ color: 'gray', marginBottom: '2px' }}>{message1}</div>
        <div className="pre-wrap">
          <b>{message2}</b>
        </div>
      </div>
    );
  };
  const onValueChanged = () => {
    setForceRender(prev => !prev);
  };

  const LabelTemplate = () => {
    return (
      <>
        <div>
          <i id="helpedInfo" className="dx-icon dx-icon-help" style={{ fontSize: '16px', paddingTop: '10px' }}></i>
        </div>
        <Tooltip target="#helpedInfo" showEvent="mouseenter" hideEvent="mouseleave" position="right">
          <div id="tooltip-content" style={{ textAlign: 'left', fontSize: '14px' }}>
            매출차감금액을 입력하시면 <b>매출-매출차감금액</b>이 배분되며, 고정경비는 함께 사용할 수 없습니다.
            <br />
          </div>
        </Tooltip>
      </>
    );
  };

  return (
    <div>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>운영 보고서 설정 관리</h2>
        </div>
        <div>
          <a
            className="manual-link"
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={profitDistributionSettingManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>

      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings parking-cost-mgmt'} style={{ paddingTop: '0px', height: 775 }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div style={{ width: '40px' }}>등록월</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
              />
              &nbsp;
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <span style={{ color: 'gray' }}></span>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <Lookup
                labelMode="static"
                stylingMode="outlined"
                dataSource={dataSource}
                valueExpr="CostCenterCode"
                displayExpr="CostCenterName"
                searchExpr="CostCenterName"
                searchEnabled={true}
                dropDownCentered={false}
                width={300}
                placeholder="사업소를 선택해주세요"
                onSelectionChanged={onSelectionChangedCenter}
                mode="search"
              >
                <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
              </Lookup>
            </ToolbarItem>
            {/* <ToolbarItem location={'before'}>
              <Button
                text="월별 설정 조회"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  const url = `/#/parking-cost-mgmt/view-month-cost`;
                  window.open(url, '_blank');
                }}
              />
            </ToolbarItem> */}
            <ToolbarItem location={'after'}>
              <Button
                icon="check"
                text="저장"
                width={80}
                disabled={!searchData.CostCenterCode}
                type="normal"
                stylingMode="contained"
                onClick={() => saveExpense()}
              />
            </ToolbarItem>
          </Toolbar>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div className={'dx-card'} style={{ padding: '15px', width: '28%', height: 700 }}>
              <form>
                <div className="">
                  <Form
                    // ref={formRef}
                    formData={formData}
                    labelMode="floating"
                    optionalMark="optional"
                    stylingMode="outlined"
                    labelLocation="top"
                  >
                    <GroupItem caption={'기본 정보'} colCount={2}>
                      <SimpleItem
                        dataField="CostCenterName"
                        colSpan={1}
                        editorType="dxTextBox"
                        editorOptions={{
                          placeholder: '사업소명 기입',
                        }}
                      >
                        <Label text="사업소명" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="RecipientName"
                        colSpan={1}
                        editorType="dxTextBox"
                        editorOptions={{
                          placeholder: '수신처를 입력해주세요',
                        }}
                      >
                        <Label text="수신처" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="RecipientEmail"
                        colSpan={2}
                        editorOptions={{
                          placeholder: '이메일을 입력해주세요. ',
                        }}
                      >
                        <Label text="메일 주소" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="CcEmail"
                        colSpan={2}
                        editorOptions={{
                          placeholder: '',
                        }}
                      >
                        <Label text="참조 메일 주소" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="SalesDeduction"
                        editorType="dxNumberBox"
                        colSpan={1}
                        editorOptions={{
                          format: '#,##0 원',
                          min: 0,
                          max: 1000000000,
                          stylingMode: 'underlined',
                        }}
                      >
                        <Label text="매출차감금액" />
                      </SimpleItem>

                      <SimpleItem editorType="dxTextBox" render={LabelTemplate} />

                      <SimpleItem dataField="IsTaxFree" colSpan={1} editorType="dxCheckBox">
                        <Label text="부가세 없음" location="left" />
                      </SimpleItem>
                      <SimpleItem dataField="IsDeficitCoverage" colSpan={1} editorType="dxCheckBox">
                        <Label text="적자보전" location="left" />
                      </SimpleItem>

                      <SimpleItem
                        dataField="SystemFormula"
                        editorType="dxTextArea"
                        colSpan={2}
                        editorOptions={{
                          placeholder: '설정값에 따라 시스템 자동 입력됩니다.',
                          readOnly: true,
                          labelMode: 'static',
                        }}
                      >
                        <Label text="산식 요약" />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={'배분 설정'} colCount={4}>
                      <SimpleItem colSpan={4} component={RangeViewer}></SimpleItem>
                      {/* <SimpleItem
                        dataField="SalesDeduction"
                        editorType="dxNumberBox"
                        colSpan={4}
                        editorOptions={{
                          format: '#,##0 원',
                          min: 0,
                          max: 1000000000,
                          stylingMode: 'underlined',
                        }}
                      >
                        <Label text="매출차감금액" />
                      </SimpleItem> */}

                      <SimpleItem
                        dataField="DistributionRate"
                        editorType="dxNumberBox"
                        colSpan={2}
                        editorOptions={{
                          placeholder: '%를 입력하세요',
                          format: percentFormat,
                          onValueChanged: onValueChanged,
                        }}
                      >
                        <Label text="갑사배분율(기본)"></Label>
                      </SimpleItem>
                      <SimpleItem
                        dataField="MinimumRevenue"
                        editorType="dxNumberBox"
                        colSpan={2}
                        editorOptions={{
                          format: '#,##0 원',
                          min: 0,
                          max: 1000000000,
                          onValueChanged: onValueChanged,
                          stylingMode: 'underlined',
                          // disabled: !threshold,
                        }}
                      >
                        <Label text="지정 최소 금액" />
                      </SimpleItem>
                      {addCount > 1 && (
                        <SimpleItem
                          dataField="DistributionRateCond1"
                          editorType="dxNumberBox"
                          colSpan={2}
                          editorOptions={{
                            placeholder: '%를 입력하세요',
                            format: percentFormat,
                            onValueChanged: onValueChanged,
                          }}
                        >
                          <Label text="갑사배분율(기준1)" />
                        </SimpleItem>
                      )}
                      {addCount > 1 && (
                        <SimpleItem
                          dataField="Cond1Revenue"
                          editorType="dxNumberBox"
                          colSpan={2}
                          editorOptions={{
                            format: '#,##0 원',
                            min: formData.MinimumRevenue,
                            placeholder: '기준 금액을 입력하세요',
                            onValueChanged: onValueChanged,
                            stylingMode: 'underlined',
                          }}
                        >
                          <Label text="기준 금액" />
                        </SimpleItem>
                      )}
                      {addCount > 2 && (
                        <SimpleItem
                          dataField="DistributionRateCond2"
                          editorType="dxNumberBox"
                          colSpan={2}
                          editorOptions={{
                            placeholder: '%를 입력하세요',
                            format: percentFormat,
                            onValueChanged: onValueChanged,
                          }}
                        >
                          <Label text="갑사배분율(기준2)" />
                        </SimpleItem>
                      )}
                      {addCount > 2 && (
                        <SimpleItem
                          dataField="Cond2Revenue"
                          editorType="dxNumberBox"
                          colSpan={2}
                          editorOptions={{
                            format: '#,##0 원',
                            min: formData.Cond1Revenue,
                            placeholder: '기준 금액을 입력하세요',
                            onValueChanged: onValueChanged,
                            stylingMode: 'underlined',
                          }}
                        >
                          <Label text="기준 금액" />
                        </SimpleItem>
                      )}
                      {addCount > 1 ? (
                        <ButtonItem
                          colSpan={2}
                          buttonOptions={{
                            text: '삭제',
                            type: 'normal',
                            icon: 'minus',
                            width: '100%',
                            onClick: onRemoveClick,
                          }}
                        />
                      ) : (
                        <EmptyItem colSpan={2} />
                      )}
                      {addCount < 3 && (
                        <ButtonItem
                          colSpan={2}
                          buttonOptions={{
                            text: '추가',
                            type: 'normal',
                            icon: 'plus',
                            width: '100%',
                            onClick: onAddClick,
                          }}
                        />
                      )}
                    </GroupItem>
                  </Form>
                </div>
              </form>
            </div>

            <div className={'dx-card'} style={{ padding: '0px', maxWidth: '72%', height: '900px' }}>
              <TabPanel deferRendering={false}>
                <TabItem title="운영 경비">
                  <div style={{ display: 'flex', padding: '0 10px' }}>
                    <div style={{ maxWidth: '50%' }}>
                      <CostGrid
                        title={'고정경비(B)'}
                        height={610}
                        dsCost={dsFixed}
                        ref={fixedRef}
                        useCustomLabel={true}
                      />
                    </div>
                    <div style={{ maxWidth: '50%' }}>
                      <CostGrid title={'변동경비(B)'} height={610} dsCost={dsFloating} ref={floatingRef} />
                    </div>
                  </div>
                </TabItem>
                <TabItem title="별도 비용">
                  <div style={{ display: 'flex', padding: '0 10px' }}>
                    <div style={{ maxWidth: '50%' }}>
                      <CostGrid
                        title={`별도지급(F)`}
                        height={610}
                        dsCost={dsDisbursement}
                        ref={disbursementRef}
                        help={'갑사 청구내역의 수익금으로 포함되는 금액'}
                        useCustomLabel={true}
                        imageSource={base64forConstantF}
                        keyID="ConstantF"
                      />
                    </div>
                    <div style={{ maxWidth: '50%' }}>
                      <CostGrid
                        title={`별도청구(G)`}
                        height={610}
                        dsCost={dsCharge}
                        ref={chargeRef}
                        help={'하이파킹 청구서에 포함되는 금액입니다.'}
                        useCustomLabel={true}
                        imageSource={base64forConstantG}
                        keyID="ConstantG"
                        visible={false}
                      />
                    </div>
                  </div>
                </TabItem>
                <TabItem title="월별 설정 이력">
                  <ParkingCostViewByMonth costData={monthlyDataSource} />
                </TabItem>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default ParkingCostMgmt;
